export const endPoints = {
    registeredModel: `${process.env.REACT_APP_APIGEE_MODEL_MANAGEMENT}api/1.0/registered-models/search?filter_string=&max_results=25&order_by=name+ASC`,
    getAllRegisteredModelVersionFromDlops: `${process.env.REACT_APP_APIGEE_MODEL_MANAGEMENT}api/1.0/onboard-models/get-projects-models-versions`,
    registeredModelVersionsFromDlops: (projectName) =>
        `${process.env.REACT_APP_APIGEE_MODEL_MANAGEMENT}api/1.0/onboard-models/get-model-versions?project_name=${projectName}`,
    accuracyDistanceForActiveModel: `${process.env.REACT_APP_APIGEE_OBSERVABILITY}api/1.0/drift_monitors/getaccuracy_distance`,
    registeredModelVersions: (modelName) => `${process.env.REACT_APP_APIGEE_MODEL_MANAGEMENT}api/1.0/model-versions/search?filter_string=name=${modelName}`,
    accuracyDistanceForNonActiveModel: `${process.env.REACT_APP_APIGEE_OBSERVABILITY}api/1.0/drift_monitors/getaccuracy_distance_archived`,
    registeredModelRunId: (getRunId) => `${process.env.REACT_APP_APIGEE_MODEL_MANAGEMENT}api/1.0/runs/get?run_id=${getRunId}`,
    createObservabiltyModel: `${process.env.REACT_APP_APIGEE_OBSERVABILITY}api/1.0/observable_models/`,
    loadBaseLineObserveModel: `${process.env.REACT_APP_APIGEE_OBSERVABILITY}api/1.0/observable_models/load_baseline_data`,
    testObserveModelEndPoint: (getRunId) => `${process.env.REACT_APP_APIGEE_OBSERVABILITY}api/1.0/observable_models/${getRunId}`,
    transitionStagingFromMlflow: `${process.env.REACT_APP_APIGEE_MODEL_MANAGEMENT}api/1.0/model-versions/transition-stage`,
    transitionStagingFromUIModel: (projectName, modelName, version) =>
        `${process.env.REACT_APP_APIGEE_MODEL_MANAGEMENT}api/1.0/onboard-models/transition-stage-to-archive?project_name=${projectName}&model_name=${modelName}&version=${version}`,
    driftImages: (_startIndex, _endIndex) =>
        `${process.env.REACT_APP_APIGEE_OBSERVABILITY}api/1.0/drift_monitors/get_drifted_images?start_index=${_startIndex}&end_index=${_endIndex}`,
    saveToBlobStorage: `${process.env.REACT_APP_APIGEE_OBSERVABILITY}api/1.0/drift_monitors/save_drifted_images`,
    saveDistribution_List: `${process.env.REACT_APP_APIGEE_OBSERVABILITY}api/1.0/observable_models/update_distribution_list`,
    saveMonitor_Threshold: `${process.env.REACT_APP_APIGEE_OBSERVABILITY}api/1.0/drift_detection/update_monitor_thresholds`,
    submitCreateNewProject: `${process.env.REACT_APP_APIGEE_MODEL_MANAGEMENT}api/1.0/onboard-models/onboard-model`,
};

export default endPoints;
