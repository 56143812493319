import React, { useState, useEffect } from 'react';
import { Box, Drawer, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';
import axios from 'axios';
import PropTypes from 'prop-types';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { StyledTableHeader, StyledTableCell } from '../../modelObservability/style';
import AccessToken from '../accessToken/accessToken';

function MetadataDrawer({ openMetadata, handleMetadataClose, data: initialData, onCloseCallback }) {
    const parseValue = (value, defaultValue) => {
        if (!value) return defaultValue;
        try {
            const parsedValue = JSON.parse(value);
            return Array.isArray(parsedValue) ? parsedValue : value.split(',').map((item) => item.trim());
        } catch (e) {
            // If JSON parsing fails, fallback to string splitting
            return value.split(',').map((item) => item.trim());
        }
    };
    const { getAccessToken } = AccessToken();
    const [data, setData] = useState(initialData);
    const dataArray = Array.isArray(data) ? data : [data];
    const distributionItem = dataArray?.find((item) => item.key === 'distribution_list');
    const emailList = distributionItem?.value.split(',').map((email) => email.trim()) || [];
    const [modelDetails, setModelDetails] = useState('');
    const [isDistributionListModified, setIsDistributionListModified] = useState(false);
    const [emails, setEmails] = useState(emailList);
    const [newEmail, setNewEmail] = useState('');
    const [error, setError] = useState('');
    const [errorConfidence, setErrorConfidence] = useState('');
    const [errorDriftMsg, setErrorDriftMsg] = useState('');
    const [warning, setWarning] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [newlyAddedEmails, setNewlyAddedEmails] = useState([]);
    const driftThreshold = dataArray?.find((item) => item.key === 'drift_distance_threshold');
    const driftArray = driftThreshold ? JSON.parse(driftThreshold.value) : [2];
    const [avgDriftDistance, setAvgDriftDistance] = useState(driftArray);
    const accuracyTag = dataArray?.find((item) => item.key === 'accuracy');
    const accuracyArray = parseValue(accuracyTag?.value, [0.1, 0.1]);
    const [avgConfidenceScore, setAvgConfidenceScore] = useState(accuracyArray[1]);
    const [isThresholdModified, setIsThresholdModified] = useState(false);

    useEffect(() => {
        if (Array.isArray(initialData)) {
            setData(initialData);

            const modelDetail = JSON.parse(localStorage.getItem('modelDetails'));
            setModelDetails(modelDetail);

            const distribution = initialData.find((item) => item.key === 'distribution_list');
            const emailsList = distribution?.value.split(',').map((email) => email.trim()) || [];

            const accuracyTags = initialData.find((item) => item.key === 'accuracy');
            const accuracyArrays = parseValue(accuracyTags?.value, [0.1, 0.1]);

            const driftDistance = initialData.find((item) => item.key === 'drift_distance_threshold');
            const driftArrays = driftDistance ? JSON.parse(driftDistance.value) : [2];

            setAvgDriftDistance(driftArrays);
            setAvgConfidenceScore(accuracyArrays[1]);
            setEmails(emailsList);
        }
    }, [initialData]);

    const columns = [
        {
            name: 'ID',
        },
        {
            name: 'Value',
        },
    ];

    const handleAddEmail = () => {
        if (!newEmail || !/\S+@\S+\.\S+/.test(newEmail)) {
            setError('Please enter a valid email address');
            return;
        }

        if (emails.includes(newEmail.trim())) {
            setError('This email is already in the list');
            return;
        }

        setEmails((prevEmails) => {
            const updatedEmails = [newEmail.trim(), ...prevEmails];
            setIsDistributionListModified(true);
            return updatedEmails;
        });
        setNewlyAddedEmails([newEmail.trim(), ...newlyAddedEmails]);
        setNewEmail('');
        setError('');
        setWarning('');
    };

    const validateAvgConfidenceScore = (value) => {
        const numValue = parseFloat(value);
        setAvgConfidenceScore(value);
        if (Number.isNaN(numValue) || numValue < 0.1 || numValue > 1.0 || !/^\d+(\.\d+)?$/.test(value)) {
            setErrorConfidence('Please enter a decimal number between 0.1 and 1.0');
            setIsThresholdModified(false);
        } else {
            setErrorConfidence('');
            setIsThresholdModified(true);
        }
    };

    const validateAvgDriftScore = (value) => {
        const numValue = parseFloat(value);
        setAvgDriftDistance(value);

        if (Number.isNaN(numValue) || numValue < 0.5 || numValue > 5 || !/^\d+(\.\d+)?$/.test(value)) {
            setErrorDriftMsg('Please enter a number between 0.5 and less than 5.');
            setIsThresholdModified(false);
        } else {
            setErrorDriftMsg('');
            setIsThresholdModified(true);
        }
    };

    const handleDeleteEmail = (emailToDelete) => {
        if (emails.length === 1) {
            setWarning('*You must add another email account before you remove the last one.');
            return;
        }

        setEmails((prevEmails) => {
            const updatedEmails = prevEmails.filter((email) => email !== emailToDelete);
            setIsDistributionListModified(true);
            if (updatedEmails.length === 1) {
                setWarning('*You must add another email account before you remove the last one.');
            } else {
                setWarning('');
            }
            return updatedEmails;
        });
    };

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
        if (emails.length === 1) {
            setWarning('*You must add another email account before you remove the last one.');
        } else {
            setWarning('');
        }
    };

    const postDistributionUpdate = async (accessToken) => {
        const projectName = dataArray?.find((tag) => tag.key === 'project_name')?.value || 'undefined';
        const payload = {
            model_name: modelDetails.name,
            model_version: modelDetails.version,
            project_name: projectName,
            distribution_list: emails.join(','),
        };
        try {
            return await axios.post(endPoints.saveDistribution_List, payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        } catch (err) {
            return err;
        }
    };
    const postThresholdData = async (accessToken) => {
        const projectName = dataArray?.find((tag) => tag.key === 'project_name')?.value || 'undefined';
        const payload = {
            model_name: modelDetails.name,
            model_version: modelDetails.version,
            project_name: projectName,
            monitor_thresholds: {
                drift_distance_threshold: avgDriftDistance,
                confidence_score_threshold: avgConfidenceScore,
            },
        };
        try {
            return await axios.post(endPoints.saveMonitor_Threshold, payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        } catch (err) {
            return err;
        }
    };

    const getRegisteredDlopsModelVersion = async (accessTokenPromise, projectName) => {
        try {
            const accessToken = await accessTokenPromise;
            const response = await axios.get(endPoints.registeredModelVersionsFromDlops(projectName), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                },
            });
            return response?.data;
        } catch (err) {
            return err;
        }
    };

    const getModelVersions = async () => {
        try {
            const modelName = localStorage.getItem('modelName');
            const getProjectNameFromStorage = localStorage.getItem('project_name');
            const newAccessToken = localStorage.getItem('accessToken');
            const getRunId = localStorage.getItem('run_Id');
            const response = await getRegisteredDlopsModelVersion(newAccessToken, getProjectNameFromStorage);
            const filterByProject = response.filter((model) => model?.tags?.some((tag) => tag.value.toLowerCase() === getProjectNameFromStorage.toLowerCase()));
            const filterByModelName = filterByProject.filter((mName) => mName.name === modelName);
            const filterByModelId = filterByModelName.filter((mRunId) => (mRunId.model_id || mRunId.run_id) === getRunId);
            return filterByModelId;
        } catch (err) {
            return err;
        }
    };

    const handleSave = async () => {
        try {
            const accessToken = getAccessToken();
            if (isThresholdModified) {
                const updateThreshold = await postThresholdData(accessToken);
                if (updateThreshold.status === 200) {
                    const updatedConfidenceScore = updateThreshold.data.data.confidence_score_threshold;
                    const updatedDriftDistance = updateThreshold.data.data.drift_distance_threshold;
                    setAvgConfidenceScore(updatedConfidenceScore);
                    setAvgDriftDistance(updatedDriftDistance);
                    const storgemodelDetails = JSON.parse(localStorage.getItem('modelDetails'));
                    storgemodelDetails.tags = storgemodelDetails.tags.map((tag) => {
                        if (tag.key === 'accuracy') {
                            return { ...tag, value: JSON.stringify([accuracyArray[1], avgConfidenceScore]) };
                        }
                        if (tag.key === 'drift_distance_threshold') {
                            return { ...tag, value: avgDriftDistance };
                        }
                        return tag;
                    });
                    localStorage.setItem('modelDetails', JSON.stringify(storgemodelDetails));
                    toast.success('Successfully saved threshold values', {
                        position: 'top-right',
                        autoClose: 3000,
                    });
                    setIsEditing(false);
                } else {
                    throw new Error('Failed to save threshold values');
                }
            }

            if (isDistributionListModified) {
                const response = await postDistributionUpdate(accessToken);
                if (response.status === 200) {
                    setNewlyAddedEmails([]);
                    toast.success('Successfully saved distribution list', {
                        position: 'top-right',
                        autoClose: 3000,
                    });

                    const updatedDistributionList = response.data.data.distribution_list.split(',').map((email) => email.trim());
                    setEmails(updatedDistributionList);

                    // const storgemodelDetails = JSON.parse(localStorage.getItem('modelDetails'));
                    // storgemodelDetails.tags = storgemodelDetails.tags.map((tag) =>
                    //     tag.key === 'distribution_list' ? { ...tag, value: updatedDistributionList.join(',') } : tag
                    // );
                    // localStorage.setItem('modelDetails', JSON.stringify(storgemodelDetails));
                    setIsEditing(false);
                } else {
                    throw new Error('Failed to save distribution list');
                }
            }
            const modelVersions = await getModelVersions();
            localStorage.setItem('modelDetails', JSON.stringify(modelVersions[0]));
            setData(modelVersions[0].tags);
        } catch (err) {
            toast.error('Failed to save MetaData Details', {
                position: 'top-right',
                autoClose: 3000,
            });

            setIsEditing(true);
        } finally {
            setWarning('');
            setIsDistributionListModified(false);
            setIsThresholdModified(false);
        }
    };

    const handleCancel = () => {
        const storgemodelDetails = JSON.parse(localStorage.getItem('modelDetails'));

        const distribution = storgemodelDetails.tags.find((item) => item.key === 'distribution_list');
        const emailsList = distribution?.value.split(',').map((email) => email.trim()) || [];

        const accuracyTags = storgemodelDetails.tags.find((item) => item.key === 'accuracy');
        const accuracyArrays = accuracyTags ? parseValue(accuracyTags.value) : [0.1];

        const driftDistance = storgemodelDetails.tags.find((item) => item.key === 'drift_distance_threshold');
        const driftArrays = driftDistance ? JSON.parse(driftDistance.value) : [2];
        setIsDistributionListModified(false);
        setAvgConfidenceScore(accuracyArrays[1]);
        setAvgDriftDistance(driftArrays);
        setEmails(emailsList);
        setIsThresholdModified(false);
        setNewEmail('');
        setError('');
        setErrorConfidence('');
        setErrorDriftMsg('');
        setWarning('');
        setNewlyAddedEmails([]);
        setIsEditing(false);
    };

    const handleClose = () => {
        const storgemodelDetails = JSON.parse(localStorage.getItem('modelDetails'));

        const distribution = storgemodelDetails.tags.find((item) => item.key === 'distribution_list');
        const emailsList = distribution?.value.split(',').map((email) => email.trim()) || [];

        const accuracyTags = storgemodelDetails.tags.find((item) => item.key === 'accuracy');
        const accuracyArrays = accuracyTags ? parseValue(accuracyTags.value) : [0.1];

        const driftDistance = storgemodelDetails.tags.find((item) => item.key === 'drift_distance_threshold');
        const driftArrays = driftDistance ? JSON.parse(driftDistance.value) : [2];
        setIsDistributionListModified(false);
        setAvgConfidenceScore(accuracyArrays[1]);
        setAvgDriftDistance(driftArrays);
        setEmails(emailsList);
        setNewEmail('');
        setError('');
        setErrorConfidence('');
        setErrorDriftMsg('');
        setIsThresholdModified(false);
        setWarning('');
        setIsEditing(false);
        setNewlyAddedEmails([]);
        handleMetadataClose();
        if (onCloseCallback) {
            onCloseCallback();
        }
    };

    return (
        <Drawer anchor="right" open={openMetadata} onClose={handleClose}>
            <Box
                sx={{
                    width: '778px',
                    height: '100vh',
                    background: '#F4F4F4 0% 0% no-repeat padding-box',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '745px',
                    }}
                >
                    <Typography
                        variant="h6"
                        style={{
                            display: 'flex',
                            color: '#021D49',
                            height: '90px',
                            width: '100%',
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            boxShadow: '0px 0px 2px #00000029',
                            borderRadius: '0px 0px 4px 4px',
                        }}
                    >
                        <IconButton onClick={handleClose} style={{ color: '#000000', marginLeft: '20px' }}>
                            <CloseIcon style={{ color: '#000000', fontSize: '36px' }} />
                        </IconButton>
                        <Typography
                            style={{
                                color: '#021D49',
                                justifyContent: 'center',
                                alignContent: 'center',
                                textAlign: 'center',
                                fontSize: '21px',
                                fontWeight: 'medium',
                                marginLeft: '200px',
                            }}
                        >
                            Meta Data Details
                        </Typography>
                    </Typography>
                </Box>
                <Box sx={{ flexGrow: 1, height: 'auto', marginTop: '16px' }}>
                    <TableContainer component={Paper}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '16px',
                                marginLeft: '30px',
                            }}
                        >
                            <Typography sx={{ color: '#021D49', fontSize: '14px', fontWeight: 'medium' }}>Drift Configuration & Distribution</Typography>
                            {isEditing ? (
                                <>
                                    <Button
                                        variant="outlined"
                                        sx={{ width: '83px', marginLeft: '250px', height: '36px', color: '#1378D4' }}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{ width: '83px', marginRight: '30px', height: '36px', backgroundColor: '#1378D4' }}
                                        onClick={handleSave}
                                        disabled={(!isThresholdModified && !isDistributionListModified) || errorConfidence || errorDriftMsg || error}
                                    >
                                        Save
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    variant="contained"
                                    sx={{ width: '83px', marginRight: '30px', height: '36px', backgroundColor: '#1378D4' }}
                                    onClick={toggleEditMode}
                                >
                                    Edit
                                </Button>
                            )}
                        </Box>
                        <Box sx={{ borderBottom: '1px solid #D3D3D3', width: '710px', padding: '10px', marginLeft: '20px', height: 'auto', flexGrow: 1 }} />
                        <Table>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        '.css-pi5f53-MuiTableCell-root': {
                                            borderBottom: 'none',
                                            marginBottom: '10px',
                                        },
                                        height: errorConfidence || errorDriftMsg ? '135px' : '100px',
                                    }}
                                >
                                    <StyledTableCell
                                        sx={{ color: '#000000', fontWeight: 'medium', wordBreak: 'break-word', width: '300px', verticalAlign: 'top' }}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography sx={{ fontWeight: 'medium', fontSize: '14px', color: '#000000' }}>Drift metrics</Typography>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell
                                        sx={{ color: '#000000', fontWeight: 'medium', wordBreak: 'break-word', width: '300px', verticalAlign: 'top' }}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                                                <Typography sx={{ fontWeight: 'medium', fontSize: '14px', color: '#000000' }}>Avg. Confidence Score</Typography>
                                                {isEditing ? (
                                                    <TextField
                                                        value={avgConfidenceScore}
                                                        onChange={(e) => validateAvgConfidenceScore(e.target.value)}
                                                        error={!!errorConfidence}
                                                        helperText={errorConfidence}
                                                        sx={{
                                                            marginTop: '10px',
                                                            width: '64px',
                                                            height: '43px',
                                                            border: errorConfidence ? '' : '1px solid #1378D4',
                                                            borderRadius: '4px',
                                                            '& .MuiInputBase-root': {
                                                                height: '100%',
                                                                // padding: '0 8px',
                                                                textAlign: 'center',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#1378D4',
                                                                },
                                                            },
                                                            '& .MuiFormHelperText-root': {
                                                                width: '250px',
                                                                fontSize: '14px',
                                                                marginRight: '10px',
                                                                marginLeft: '0px',
                                                                marginBottom: errorConfidence ? '10px' : '30px',
                                                            },
                                                        }}
                                                        variant="outlined"
                                                    />
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            marginTop: '10px',
                                                            width: '64px',
                                                            height: '43px',
                                                            border: '1px solid #616161',
                                                            borderRadius: '4px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: '14px', color: '#000000' }}>{avgConfidenceScore || '0.1'}</Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '120px' }}>
                                                <Typography sx={{ fontWeight: 'medium', fontSize: '14px', color: '#000000' }}>Avg. Drift Distance</Typography>
                                                {isEditing ? (
                                                    <TextField
                                                        value={avgDriftDistance}
                                                        onChange={(e) => validateAvgDriftScore(e.target.value)}
                                                        error={!!errorDriftMsg}
                                                        helperText={errorDriftMsg}
                                                        sx={{
                                                            marginTop: '10px',
                                                            width: '64px',
                                                            height: '43px',
                                                            border: errorDriftMsg ? '' : '1px solid #1378D4',
                                                            borderRadius: '4px',
                                                            '& .MuiInputBase-root': {
                                                                height: '100%',
                                                                textAlign: 'center',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: '#1378D4',
                                                                },
                                                            },
                                                            '& .MuiFormHelperText-root': {
                                                                width: '250px',
                                                                fontSize: '14px',
                                                                marginRight: '10px',
                                                                marginLeft: 0,
                                                                marginBottom: errorDriftMsg ? '10px' : '30px',
                                                            },
                                                        }}
                                                        variant="outlined"
                                                    />
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            marginTop: '10px',
                                                            width: '64px',
                                                            height: '43px',
                                                            border: '1px solid #616161',
                                                            borderRadius: '4px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: '14px', color: '#000000' }}>{avgDriftDistance}</Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    {' '}
                                    <StyledTableCell
                                        sx={{ color: '#000000', fontWeight: 'medium', wordBreak: 'break-word', width: '300px', verticalAlign: 'top' }}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography sx={{ fontWeight: 'medium', fontSize: '14px', color: '#000000' }}>Distribution List</Typography>
                                            <Typography sx={{ color: '#616161 !important', fontSize: '13px', paddingTop: '5px', width: '148px' }}>
                                                There must be at least one email account.
                                            </Typography>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ color: '#000000', wordBreak: 'break-word' }}>
                                        {isEditing && (
                                            <Box>
                                                <TextField
                                                    id="filled-email-input"
                                                    label="ADD EMAIL ACCOUNT"
                                                    type="email"
                                                    autoComplete="current-email"
                                                    variant="filled"
                                                    value={newEmail}
                                                    onChange={(e) => {
                                                        setNewEmail(e.target.value);
                                                        if (error) {
                                                            setError('');
                                                        }
                                                    }}
                                                    sx={{
                                                        width: '503px',
                                                        background: '#F2F2F2 0% 0% no-repeat padding-box',
                                                        boxShadow: '0px 1px 1px #00000065',
                                                        '& .MuiFilledInput-root': {
                                                            '&:before': {
                                                                borderBottomColor: '#888888',
                                                            },
                                                            '&:hover:not(.Mui-disabled):before': {
                                                                borderBottomColor: '#888888',
                                                            },
                                                            '&:after': {
                                                                borderBottomColor: '#888888',
                                                            },
                                                            '&.Mui-focused:before': {
                                                                borderBottomColor: '#888888',
                                                            },
                                                            '&.Mui-focused': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: '#000000',
                                                            fontSize: '15px',
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: '#888888',
                                                        },
                                                    }}
                                                />
                                                {error && <Typography sx={{ color: '#FF0000', fontSize: '14px', paddingTop: '5px' }}>{error}</Typography>}
                                                <Button
                                                    variant="contained"
                                                    sx={{ width: '121px', height: '36px', marginTop: '10px', backgroundColor: '#1378D4' }}
                                                    onClick={handleAddEmail}
                                                >
                                                    Add
                                                </Button>
                                            </Box>
                                        )}
                                        <Box sx={{ marginBottom: '15px', marginTop: isEditing === false ? '' : '20px' }}>
                                            {emails &&
                                                emails.map((email, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            marginBottom: '10px',
                                                            background: newlyAddedEmails.includes(email) ? '#E0F7FA' : '#FFFFFF',
                                                            borderRadius: '4px',
                                                            width: '502px',
                                                            height: '60px',
                                                            border: '1px solid #D6D6D6',
                                                            padding: '10px',
                                                            paddingTop: isEditing === false ? '15px' : '',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                marginBottom: '8px',
                                                            }}
                                                        >
                                                            <Typography sx={{ color: '#000000', fontSize: '14px' }}>{email}</Typography>
                                                            {isEditing && (
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{
                                                                        width: '94px',
                                                                        height: '36px',
                                                                        border: '1px solid #1378D4',
                                                                        color: '#1378D4',
                                                                        borderRadius: '4px',
                                                                    }}
                                                                    onClick={() => handleDeleteEmail(email)}
                                                                    disabled={emails.length === 1}
                                                                >
                                                                    Remove
                                                                </Button>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                ))}
                                            {emails.length <= 0 ? (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        marginBottom: '10px',
                                                        borderRadius: '4px',
                                                        width: '502px',
                                                        height: '60px',
                                                        border: '1px solid #D6D6D6',
                                                        padding: '10px',
                                                        paddingTop: isEditing === false ? '15px' : '',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            marginBottom: '8px',
                                                        }}
                                                    >
                                                        <Typography sx={{ color: '#000000', fontSize: '14px' }}>There is no email account</Typography>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                ' '
                                            )}
                                            {isEditing && warning && (
                                                <Typography sx={{ color: '#1378D4', fontSize: '14px', marginTop: '10px' }}>{warning}</Typography>
                                            )}
                                        </Box>
                                    </StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box sx={{ flexGrow: 1, overflow: 'auto', marginTop: '16px' }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => (
                                        <StyledTableHeader key={index} style={{ borderTop: '1px solid lightgrey' }}>
                                            {column.name}
                                        </StyledTableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(dataArray)?.map((row, index1) => (
                                    <React.Fragment key={index1}>
                                        <TableRow key={index1}>
                                            <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal', wordBreak: 'break-word', width: '300px' }}>
                                                {row[1]?.key}
                                            </StyledTableCell>
                                            <StyledTableCell sx={{ color: '#000000', wordBreak: 'break-word' }}>{row[1]?.value}</StyledTableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Button
                    onClick={handleClose}
                    style={{
                        marginTop: '16px',
                        justifyContent: 'center',
                        height: '69px',
                        background: '#1378D4 0% 0% no-repeat padding-box',
                        color: 'white',
                    }}
                >
                    Close
                </Button>
            </Box>
        </Drawer>
    );
}

MetadataDrawer.propTypes = {
    openMetadata: PropTypes.bool,
    handleMetadataClose: PropTypes.func,
    data: PropTypes.array,
    onCloseCallback: PropTypes.func,
};

MetadataDrawer.defaultProps = {
    openMetadata: false,
    handleMetadataClose: () => {},
    data: [],
    onCloseCallback: () => {},
};

export default MetadataDrawer;
