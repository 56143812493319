/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { ErrorBoundary } from 'react-error-boundary';
import toast from 'react-hot-toast';
import ErrorFallback from 'components/error-fallback';
import {
    Drawer,
    Box,
    Paper,
    CardContent,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Typography,
    Button,
    CircularProgress,
    FormControl,
    OutlinedInput,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    DialogContentText,
    IconButton,
    Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { ProgressIcon } from '../style';
import MetadataDrawer from '../components/metaDataDrawer/index';
import PaperComponent from '../components/model/materialPopup';
import rightArrow from '../../../assets/images/dlopsImages/rightarrow.png';
import stickyNotes from '../../../assets/images/dlopsImages/sticky-note-solid.svg';
import Overview2 from '../../../assets/images/dlopsImages/Overview-2-d.svg';
import { Item, StyledCard, StyledTableHeader, StyledTableCell, style } from './style';
import AccessToken from '../components/accessToken/accessToken';

const ModelRegistryDashboardView = () => {
    const { getAccessToken, handleTokenError } = AccessToken();
    const [isLoading, setLoading] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [tableData, setTableData] = useState({});
    const [metrixcards, setMetrixCards] = useState([]);
    const [mlTabledata, setMlTabledata] = useState({});
    const [activeModelData, setActiveModelData] = useState([]);
    const [getObservabilityModelData, setObservabilityModelData] = useState(false);
    const [getBaselineStatus, setBaselineStatus] = useState(null);
    const [getArizeModelUrl, setArizeModelUrl] = useState('');
    const [envType, setEnvType] = useState(null);
    const [displayPopup, setDisplayPopup] = useState(false);
    const [displayPopupForButton, setDisplayPopupForButton] = useState(false);
    const [popupState, setPopupState] = useState(false);
    const [open, setOpen] = React.useState(true);
    const [archiveExistingVersions, setArchiveExistingVersions] = useState(true);
    const [openMetadata, setOpenMetadata] = useState(false);
    const [tagsModelData, setTagsModelData] = useState('');
    const [iframeUrl, setIframeUrl] = useState('');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const history = useHistory();
    const columns = [
        { name: '' },
        {
            name: 'Project name',
            renderCell: ({ row }) => row.projectName || 'undefined',
        },
        {
            name: 'Model name',
            renderCell: ({ row }) => row.ModelName || 'undefined',
        },
        {
            name: 'Stage',
            renderCell: ({ row }) => row.Stage || 'undefined',
        },
        { name: 'Version', renderCell: ({ row }) => row.Version || 'undefined' },
        {
            name: 'Meta data',
            renderCell: ({ row }) => row.projectName || 'undefined',
        },
        ...(activeModelData
            ? [
                  {
                      name: 'Drift detection',
                      renderCell: ({ row }) => row.Driftdetection || 'undefined',
                  },
              ]
            : []),
    ];
    if (getBaselineStatus && getBaselineStatus !== 'undefined' && getBaselineStatus !== null) {
        columns.push({
            name: 'Status',
            renderCell: ({ row }) => row.Status || '',
        });
    }

    const fetchRunId = async (accessToken) => {
        const getRunId = localStorage.getItem('run_Id');
        try {
            const responseData = await axios.get(endPoints.registeredModelRunId(getRunId), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return responseData?.data;
        } catch (error) {
            return handleTokenError(error, endPoints.registeredModelRunId(getRunId), 'get', null, 'registertedToken');
        }
    };

    const handleHyperlinkClick = (url) => {
        setIframeUrl(url);
        setIsDrawerOpen(true);
    };

    const toggleDrawer = (drawErOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(drawErOpen);
    };
    // eslint-disable-next-line consistent-return
    const getDashboardData = async () => {
        setLoading(true);
        // const oktaClientId = process.env.REACT_APP_DLOPS_REGISTERED_MODEL_CLIENTID;
        // const oktaClientSecret = process.env.REACT_APP_DLOPS_REGISTERED_MODEL_CLIENTSECRET;

        try {
            // const accessToken = await getOrRefreshAccessToken(oktaClientId, oktaClientSecret);
            const accessToken = await getAccessToken();
            const response = await fetchRunId(accessToken);
            if (response?.run) {
                setMetrixCards(response?.run?.data?.metrics);
                localStorage.setItem('run_id_details', JSON.stringify(response));
                setTableData(response?.run);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            return error;
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const getActiveDashboard = (modelDetails) => {
        if (modelDetails.current_stage === 'Staging' || modelDetails.current_stage === 'Production') {
            setActiveModelData(true);
        } else {
            setActiveModelData(false);
        }
    };
    const closePopup = () => {
        setDisplayPopupForButton(false);
    };

    const formatTimeStamp = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    const postObservabilityModel = async (accessToken, onBoardApiRequest, oktaClientIdObservability, oktaClientSecretObservabilty) => {
        try {
            return await axios.post(endPoints.createObservabiltyModel, onBoardApiRequest, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.response?.statusText || 'An error occurred while posting the observability model.';
            toast.error(errorMessage, {
                position: 'top-right',
                autoClose: 3000,
            });

            // Call the handleTokenError function if necessary
            return handleTokenError(
                error,
                oktaClientIdObservability,
                oktaClientSecretObservabilty,
                endPoints.createObservabiltyModel,
                'post',
                onBoardApiRequest,
                'observabilityToken'
            );
        }
    };

    const fetchObservabilityModel = async (runId, accessToken) => {
        try {
            const response = await axios.get(endPoints.testObserveModelEndPoint(runId), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            return response?.data;
        } catch (error) {
            return handleTokenError(error, endPoints.testObserveModelEndPoint(runId), 'get', null);
        }
    };

    const getObservabilityModel = async () => {
        setLoading(true);
        try {
            const getRunId = localStorage.getItem('run_Id');
            const accessToken = await getAccessToken();
            const response = await fetchObservabilityModel(getRunId, accessToken);

            if (response?.response?.status === 404 && response?.response?.data?.message.includes('No model with this model_id')) {
                setObservabilityModelData(true);
            } else {
                setObservabilityModelData(false);
                setBaselineStatus(response?.data?.model?.baseline_ingestion_status);
                setArizeModelUrl(response?.data?.model?.arize_model_url);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error?.response?.status === 404 && error?.response?.message.includes('No model with this model_id')) {
                setObservabilityModelData(true);
            } else {
                setObservabilityModelData(false);
            }
        }
    };

    const updateObservabilityModel = async (modelData) => {
        const createTimeStamp = formatTimeStamp(modelData.creation_timestamp);
        const updateTimeStamp = formatTimeStamp(modelData?.last_updated_timestamp || modelData?.creation_timestamp);
        const modelType = modelData.tags.find((tag) => tag.key === 'model_type');
        const projectName = modelData.tags.find((tag) => tag.key === 'project_name');
        const market = modelData.tags.find((tag) => tag.key.toLowerCase() === 'market');
        const baselineSource = modelData.tags.find((tag) => tag.key === 'baseline_datasource');
        const storageAccount = modelData.tags.find((tag) => tag.key === 'storage_account');
        const containerName = modelData.tags.find((tag) => tag.key === 'container_name');
        const modelName = modelData.tags.find((tag) => tag.key === 'model_name');
        const frameWork = modelData.tags.find((tag) => tag.key === 'framework');
        const runId = modelData?.run_id;
        const onBoardApiRequest = {
            model_description: modelData.description,
            model_type: modelType ? modelType.value : 'undefined',
            model_deployed: true,
            project_name: projectName ? projectName.value : 'undefined',
            market: market ? market.value : 'undefined',
            container_name: containerName ? containerName.value : 'undefined',
            model_id: runId || modelData?.model_id,
            model_name: modelName ? modelName.value : modelData?.name,
            model_version: modelData.version,
            baseline_datasource: baselineSource ? baselineSource.value : 'undefined',
            framework: frameWork ? frameWork.value : 'undefined',
            storage_account: storageAccount ? storageAccount.value : 'undefined',
            creation_date: createTimeStamp,
            updated_date: updateTimeStamp,
            baseline_ingestion_status: 'In-Progress',
        };
        const loadBaseLineRequest = {
            date_time: '2023-01-29 00:00:00.00',
            model_id: runId || modelData?.model_id,
        };

        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST';
        setLoading(true);
        try {
            const accessToken = await getAccessToken();
            const response = await postObservabilityModel(accessToken, onBoardApiRequest);
            if (response && response.status === 201) {
                await axios.post(endPoints.loadBaseLineObserveModel, loadBaseLineRequest, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
            }
            setLoading(false);
            setObservabilityModelData(false);
            const modelDetails = JSON.parse(localStorage.getItem('modelDetails'));
            if (modelDetails.current_stage === 'Production') {
                getObservabilityModel();
            }
        } catch (err) {
            // Handle errors
            setLoading(false);
            setObservabilityModelData(true);
        }
    };

    const onLeave = () => {
        setPopupState(false);
    };

    const handleClose = () => {
        setOpen(false);
        setEnvType(mlTabledata.current_stage);
    };

    const handleChange = (e) => {
        if (e != null && e.target.checked === true) {
            setArchiveExistingVersions(true);
        } else {
            setArchiveExistingVersions(false);
        }
    };
    const getRegisteredDlopsModelVersion = async (accessTokenPromise, projectName) => {
        setLoading(true);
        try {
            const accessToken = await accessTokenPromise;
            const response = await axios.get(endPoints.registeredModelVersionsFromDlops(projectName), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                },
            });
            setLoading(false);
            return response?.data;
        } catch (error) {
            setLoading(false);
            return error;
        }
    };
    const getModelVersions = async () => {
        try {
            setLoading(true);
            const modelName = localStorage.getItem('modelName');
            const getProjectNameFromStorage = localStorage.getItem('project_name');
            const newAccessToken = localStorage.getItem('accessToken');
            const getRunId = localStorage.getItem('run_Id');
            const response = await getRegisteredDlopsModelVersion(newAccessToken, getProjectNameFromStorage);
            const filterByProject = response.filter((model) => model?.tags?.some((tag) => tag.value.toLowerCase() === getProjectNameFromStorage.toLowerCase()));
            const filterByModelName = filterByProject.filter((mName) => mName.name === modelName);
            const filterByModelId = filterByModelName.filter((mRunId) => (mRunId.model_id || mRunId.run_id) === getRunId);
            return filterByModelId;
        } catch (error) {
            setLoading(false);
            return error;
        }
    };

    // eslint-disable-next-line consistent-return
    const refreshData = async () => {
        try {
            const modelDetails = JSON.parse(localStorage.getItem('modelDetails'));
            setTagsModelData(modelDetails?.tags || []);
        } catch (error) {
            return error;
        }
    };

    const handleMetadataClose = () => {
        setOpenMetadata(false);
    };

    const postStageUpdate = async (accessToken) => {
        const projectName = mlTabledata?.tags.find((tag) => tag.key === 'project_name')?.value || 'undefined';
        const modelName = mlTabledata.name;
        const { version } = mlTabledata;
        const modelVersionStorage = localStorage.getItem('modelVersionsCheck');
        const payload = {
            name: mlTabledata.name,
            version: mlTabledata.version,
            stage: envType,
            archive_existing_versions: envType === 'Archived' || envType === 'None' ? 'false' : archiveExistingVersions,
        };
        try {
            if (modelVersionStorage === 'true') {
                return await axios.post(endPoints.transitionStagingFromUIModel(projectName, modelName, version), null, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
            }

            return await axios.post(endPoints.transitionStagingFromMlflow, payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            let endpoint;
            let payloadError;
            if (modelVersionStorage === 'true') {
                endpoint = endPoints.transitionStagingFromUIModel(projectName, modelName, version);
                payloadError = null;
            } else {
                endpoint = endPoints.transitionStagingFromMlflow;
                payloadError = payload;
            }
            return handleTokenError(error, endpoint, 'post', payloadError);
        }
    };

    const updateStage = async () => {
        setDisplayPopup(false);
        setOpen(false);
        setLoading(true);
        try {
            const accessToken = await getAccessToken();
            const response = await postStageUpdate(accessToken);
            setLoading(false);
            return response;
        } catch (err) {
            setLoading(false);
            toast(err.message, {
                position: 'top-right',
                autoClose: 3000,
            });
            return err.message;
        }
    };
    const getModelOnboardedMetrics = (tags) => {
        const modelMetricsTag = tags.find((tag) => tag.key === 'model_metrics');
        const extractedValues = {};
        if (modelMetricsTag) {
            const modelMetricsValue = modelMetricsTag.value;
            let modelMetrics;
            try {
                modelMetrics = JSON.parse(modelMetricsValue.replace(/'/g, '"'));
            } catch (error) {
                const metricsArray = modelMetricsValue.split(',');
                metricsArray.forEach((metric) => {
                    const trimmedMetric = metric.trim();
                    const metricTag = tags.find((tag) => tag.key === trimmedMetric);
                    if (metricTag) {
                        extractedValues[trimmedMetric] = parseFloat(metricTag.value);
                    }
                });
            }
            if (Array.isArray(modelMetrics)) {
                modelMetrics.forEach((metric) => {
                    const trimmedMetric = metric.trim();
                    const metricTag = tags.find((tag) => tag.key === trimmedMetric);

                    if (metricTag) {
                        let metricValues;

                        try {
                            metricValues = JSON.parse(metricTag.value.trim());
                        } catch (error) {
                            return;
                        }

                        if (Array.isArray(metricValues)) {
                            extractedValues[trimmedMetric] = parseFloat(metricValues[0]);
                        }
                    }
                });
            } else if (typeof modelMetrics === 'string') {
                const metricsArray = modelMetrics.split(',');
                metricsArray.forEach((metric) => {
                    const trimmedMetric = metric.trim();
                    const metricTag = tags.find((tag) => tag.key === trimmedMetric);
                    if (metricTag) {
                        extractedValues[trimmedMetric] = parseFloat(metricTag.value);
                    }
                });
            }
        }
        const formattedValues = Object.keys(extractedValues).map((key) => ({
            key,
            value: extractedValues[key],
        }));
        setMetrixCards(formattedValues);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const modelDetails = JSON.parse(localStorage.getItem('modelDetails'));
                const modelVersionStorage = localStorage.getItem('modelVersionsCheck');
                setMlTabledata(modelDetails);

                if (modelVersionStorage === 'false') {
                    getDashboardData();
                    setTagsModelData(modelDetails?.tags);
                } else {
                    const modelVersions = await getModelVersions();
                    setTagsModelData(modelVersions[0]?.tags);
                    getModelOnboardedMetrics(modelDetails?.tags);
                    setLoading(false);
                }

                getActiveDashboard(modelDetails);

                if (modelDetails.current_stage === 'Staging' || modelDetails.current_stage === 'Production') {
                    getObservabilityModel();
                }
            } catch (error) {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getEnvType = (event) => {
        if (event != null) {
            setEnvType(event?.target?.value);
            setDisplayPopup(true);
            setOpen(true);
        } else {
            setEnvType([]);
        }
    };

    let envOptions1 = [
        {
            value: 'None',
            label: 'Transition to',
            customAbbreviation: 'None',
            img: rightArrow,
            color: '#000',
            backgroundColor: 'lightgray',
        },
        {
            value: 'Production',
            label: 'Transition to',
            customAbbreviation: 'Production',
            img: rightArrow,
            color: '#000',
            backgroundColor: '#308613',
        },
        {
            value: 'Archived',
            label: 'Transition to',
            customAbbreviation: 'Archived',
            img: rightArrow,
            color: '#fff',
            backgroundColor: 'gray',
        },
    ];

    if (localStorage.getItem('modelVersionsCheck') === 'true') {
        envOptions1 = envOptions1.filter((option) => option.value !== 'None');
    }

    const filteredOptions = envOptions1.filter((element) => element.value !== mlTabledata?.current_stage);

    const handleNavigation = () => {
        history.push('/dlops-model-versions');
    };
    const goToCompareModel = () => {
        history.push('/dlops-model-comparision');
    };

    const handleMetadataClickOpen = () => {
        setOpenMetadata(true);
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>DLOPS Dashboard</title>
            </Helmet>
            <Box style={{ marginBottom: '20px' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <Item>
                                <div
                                    style={{
                                        marginLeft: '28px',
                                        marginTop: '8px',
                                        fontSize: '14px',
                                    }}
                                >
                                    <span>DLOPS</span>
                                    <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleNavigation()}>
                                        /Model versions
                                    </span>
                                    <span style={{ fontWeight: 'bold', color: '#1378D4' }}>/Model observability</span>
                                </div>
                            </Item>
                            <Button
                                style={{
                                    background: '#309BF8 0% 0% no-repeat padding-box',
                                    borderRadius: '15px',
                                    color: '#ffffff',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    marginTop: '-40px',
                                    textTransform: 'none',
                                    float: 'right',
                                    marginRight: '100px',
                                }}
                                onClick={() => goToCompareModel()}
                            >
                                + Compare model
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
                {isLoading ? (
                    <Box sx={ProgressIcon.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                        <Typography variant="body2" sx={ProgressIcon.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <div style={style.dataTableModelRegistry}>
                            <div style={style.modelRegistryDiv}>
                                <img src={stickyNotes} alt={stickyNotes} style={style.stickyNote} />
                                <h5 style={style.projectText}>Model registry</h5>
                                {/* <div style={style.subText2B}>Below you can see the registered models information and its metrics</div> */}
                            </div>
                            {localStorage.getItem('modelVersionsCheck') === 'true' && mlTabledata?.current_stage === 'Archived' ? (
                                ''
                            ) : (
                                <div style={{ width: '350px', marginRight: '10px' }}>
                                    <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                                        <Select
                                            displayEmpty
                                            value={envType}
                                            onChange={getEnvType}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                if (!Array.isArray(selected)) {
                                                    return selected || <em>{mlTabledata.current_stage}</em>;
                                                }

                                                if (selected.length === 0) {
                                                    return <em>{mlTabledata.current_stage}</em>;
                                                }

                                                return selected.join(', ');
                                            }}
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {filteredOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    <span style={{ color: option.color }} />
                                                    {option.label} &nbsp;
                                                    <img width="25px" src={option.img} alt="img1" /> &nbsp;
                                                    {option.customAbbreviation}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                        </div>
                        <div style={style.dataTableDashboardModelRegistry}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, index) => (
                                                <StyledTableHeader key={index} maxWidth={column.maxWidth}>
                                                    {column.name}
                                                </StyledTableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <>
                                            <TableRow>
                                                <StyledTableCell />
                                                <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                    {localStorage.getItem('project_name')}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ color: '#000000' }}>
                                                    {getBaselineStatus === 'Success' ? (
                                                        <>
                                                            <a href="#!" onClick={() => handleHyperlinkClick(getArizeModelUrl)} style={{ color: '#1378D4' }}>
                                                                {mlTabledata?.name}
                                                            </a>
                                                        </>
                                                    ) : (
                                                        mlTabledata?.name
                                                    )}
                                                </StyledTableCell>

                                                <StyledTableCell sx={{ color: '#000000' }}>{mlTabledata?.current_stage}</StyledTableCell>
                                                <StyledTableCell sx={{ color: '#000000' }}>{mlTabledata?.version}</StyledTableCell>
                                                <StyledTableCell
                                                    sx={{ color: '#1378D4', textDecoration: 'underline', cursor: 'pointer' }}
                                                    onClick={handleMetadataClickOpen}
                                                >
                                                    Meta data
                                                </StyledTableCell>
                                                {activeModelData ? (
                                                    <StyledTableCell sx={{ color: '#000000' }}>
                                                        {getObservabilityModelData || getBaselineStatus === 'In-Progress' || getBaselineStatus === 'Failed' ? (
                                                            <>
                                                                <Button
                                                                    style={{
                                                                        background: '#309BF8 0% 0% no-repeat padding-box',
                                                                        borderRadius: '15px',
                                                                        color: '#ffffff',
                                                                        fontSize: '12px',
                                                                        fontWeight: 500,
                                                                        marginBottom: '15px',
                                                                        textTransform: 'none',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                    onClick={() => {
                                                                        if (getBaselineStatus === 'In-Progress') {
                                                                            setDisplayPopupForButton(true);
                                                                        } else {
                                                                            updateObservabilityModel(mlTabledata);
                                                                        }
                                                                    }}
                                                                >
                                                                    Baseline
                                                                    <Tooltip
                                                                        interactive
                                                                        componentsProps={{
                                                                            tooltip: {
                                                                                sx: {
                                                                                    fontSize: '12px',
                                                                                    maxWidth: '300px',
                                                                                },
                                                                            },
                                                                        }}
                                                                        title="A baseline distribution refers to a model dataset used as a reference or comparison to the model’s current (production) distribution. Baseline distributions in Arize AI’s platform can be training datasets, validation datasets, or prior time periods of production."
                                                                    >
                                                                        <IconButton size="small" sx={{ marginLeft: '5px', padding: '0' }}>
                                                                            <InfoIcon sx={{ color: '#ffffff' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <div style={{ color: '#000000', display: 'flex' }}>
                                                                <img src={Overview2} alt="checkMark" style={{ marginRight: '5px' }} />
                                                                Baselined
                                                            </div>
                                                        )}
                                                    </StyledTableCell>
                                                ) : (
                                                    ''
                                                )}
                                                {getBaselineStatus && getBaselineStatus !== 'undefined' && getBaselineStatus !== null ? (
                                                    <StyledTableCell sx={{ color: '#000000' }}>{getBaselineStatus}</StyledTableCell>
                                                ) : (
                                                    ''
                                                )}
                                            </TableRow>
                                        </>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                            <Box
                                sx={{
                                    width: '99vw',
                                    height: '100vw',
                                    backgroundColor: 'white',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '16px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        height: '122px',
                                        paddingLeft: '16px', // Add some padding for better spacing
                                    }}
                                >
                                    <IconButton onClick={() => setIsDrawerOpen(false)} style={{ color: '#021D49', marginLeft: '50px' }}>
                                        <CloseIcon style={{ color: '#021D49', fontSize: '36px' }} />
                                    </IconButton>
                                    <Typography
                                        style={{ font: 'normal normal medium 21px PP Object Sans', color: '#021D49', marginLeft: '10px' }}
                                        component="span"
                                    >
                                        Hide Model Observability Panel
                                    </Typography>
                                </Box>
                                <Box sx={{ flexGrow: 1, overflow: 'auto', marginTop: '16px' }}>
                                    <iframe src={iframeUrl} title="Testing iFrame" style={{ width: '100%', height: '100%' }} scrolling="no" />
                                </Box>
                            </Box>
                        </Drawer>
                        {metrixcards && metrixcards.length > 0 ? (
                            <>
                                <div style={style.dataTableFlex} className="dlops_margin_left">
                                    <h5 style={style.projectText}>Performance Metrics -{mlTabledata?.name} </h5>
                                    {/* <h6 style={style.subText2}>Below you can see the metrics scores from the registered model that user selected</h6> */}
                                </div>
                                <Box display="inline-flex" flexWrap="wrap" justifyContent="flex-start" style={{ marginLeft: '15px' }}>
                                    {metrixcards.map((item, index) => (
                                        <StyledCard key={index} style={style.cardContainer}>
                                            <CardContent>
                                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={style.cardText}>
                                                    <Typography variant="h6" style={style.TypographyTexth2}>
                                                        {item.key === 'accuracy' ? 'Training accuracy' : item.key === 'loss' ? 'Training loss' : item.key}
                                                    </Typography>
                                                    <Typography variant="h2" style={style.TypographyText2}>
                                                        {item.value?.toFixed(2)}
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                        </StyledCard>
                                    ))}
                                </Box>
                            </>
                        ) : (
                            ''
                        )}
                    </>
                )}
                {/* <div style={style.iframeContainer}>
                    <iframe src={getArizeModelUrl} title="Testing iFrame" style={style.iframe} scrolling="no" />
                </div> */}
            </Box>
            {displayPopupForButton === true ? (
                <Dialog open={displayPopupForButton} onClose={closePopup}>
                    <DialogTitle>
                        Model Onboarding Status
                        <IconButton aria-label="close" onClick={closePopup} sx={{ position: 'absolute', right: 8, top: 8 }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>Model onboarding is in progress. Please check after sometime.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closePopup} style={{ lineHeight: '43px', color: '#1378D4' }}>
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : (
                ''
            )}

            {displayPopup === true ? (
                <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Stage Transition
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                    {'Transition to '}
                                    <span style={{ marginLeft: '10px' }}>
                                        <img width="25px" src={rightArrow} alt="img" /> &nbsp;
                                    </span>
                                    <span style={{ backgroundColor: 'lightgray', color: '#000', padding: '2px 8px 2px 8px', borderRadius: '4px' }}>
                                        {envType}
                                    </span>
                                </div>

                                {envType === 'None' || envType === 'Archived' ? null : (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px' }}>
                                        <div style={{ marginLeft: '-5px' }}>
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handleChange(e)}
                                                defaultChecked
                                                style={{ height: '20px', width: '20px', marginTop: '5px', marginRight: '3px' }}
                                            />
                                        </div>
                                        <div>
                                            Transition existing{' '}
                                            <span
                                                style={{
                                                    backgroundColor: envType === 'Production' ? '#308613' : '#FFCB66',
                                                    color: '#fff',
                                                    padding: '2px 8px 2px 8px',
                                                    borderRadius: '4px',
                                                }}
                                            >
                                                {envType}
                                            </span>{' '}
                                            model versions to{' '}
                                            <span style={{ backgroundColor: 'gray', color: '#fff', padding: '2px 8px 2px 8px', borderRadius: '4px' }}>
                                                Archived
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button autoFocus onClick={handleClose} style={{ backgroundColor: 'none' }}>
                            Cancel
                        </Button>
                        <Button onClick={updateStage}>OK</Button>
                    </DialogActions>
                </Dialog>
            ) : null}
            {popupState ? (
                <div
                    style={{
                        position: 'fixed',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #C5C5C5',
                        borderRadius: '2px',
                        width: 'auto',
                        height: '100px',
                    }}
                    onMouseLeave={() => onLeave()}
                >
                    <div
                        className="ML-Popup-Item"
                        aria-hidden="true"
                        onClick={() => {
                            getObservabilityModel();
                        }}
                    >
                        Test Observe Model
                    </div>
                    <div
                        aria-hidden="true"
                        className={`ML-Popup-Item ${getObservabilityModelData ? 'enabled' : 'disabled'}`}
                        onClick={() => updateObservabilityModel(mlTabledata)}
                    >
                        Observe Model
                    </div>
                </div>
            ) : null}
            <MetadataDrawer openMetadata={openMetadata} handleMetadataClose={handleMetadataClose} data={tagsModelData} onCloseCallback={refreshData} />
        </ErrorBoundary>
    );
};
export default ModelRegistryDashboardView;
