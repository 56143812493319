import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { getLoggedUserGPID } from 'utils';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ErrorFallback from 'components/error-fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { CardContent, Card, CardActions, Button, CircularProgress, Box, Tooltip } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { Item, ProgressIcon } from '../style';
import RoboNoProjects from '../../../assets/images/dlopsImages/Robo_DLOPS.svg';
import stickyNotes from '../../../assets/images/dlopsImages/sticky-note-solid.svg';
import AccessToken from '../components/accessToken/accessToken';

const ProjectDashboard = () => {
    const { handleTokenError } = AccessToken();
    const [uniqueProjectDetails, setUniqueProjectDetails] = useState();
    const [isLoading, setLoading] = useState(true);
    const [dlopsProjectModelData, setDlopsProjectModelData] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isTechnicalError, setTechnicalError] = useState('');
    const history = useHistory();
    const localStorageToken = localStorage.getItem('accessToken');

    const goToRegistry = (item) => {
        let isEqual = false;
        if (dlopsProjectModelData?.registered_models) {
            const getProjectName = dlopsProjectModelData?.registered_models.flatMap((items) => {
                return items.name;
            });
            isEqual = getProjectName.some((projectName) => item === projectName);
        }
        localStorage.setItem('modelVersionsCheck', isEqual);
        localStorage.setItem('project_name', item);
        history.push('/dlops-registered-models');
    };

    const fetchProjectList = async (accessToken, gpid) => {
        try {
            const responseData = await axios.get(endPoints.registeredModel, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    gpid,
                },
            });
            return responseData?.data;
        } catch (error) {
            return handleTokenError(error, endPoints.registeredModel, 'get', null);
        }
    };

    const getRegisteredDlopsModelVersion = async (accessTokenPromise, gpid) => {
        setLoading(true);
        try {
            const accessToken = await accessTokenPromise;
            const response = await axios.get(endPoints.getAllRegisteredModelVersionFromDlops, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                    gpid,
                },
            });
            setLoading(false);
            return response?.data;
        } catch (error) {
            setLoading(false);
            return error;
        }
    };

    const getProjectList = async (accessToken) => {
        // const accessToken = await getAccessToken();
        setLoading(true);
        try {
            const gpid = getLoggedUserGPID();
            const registeredModelResponse = await fetchProjectList(accessToken, gpid);
            const onBoardedProjectModel = await getRegisteredDlopsModelVersion(accessToken, gpid);
            setDlopsProjectModelData(onBoardedProjectModel);

            let mergeModelData;

            if (registeredModelResponse?.registered_models && onBoardedProjectModel?.registered_models) {
                mergeModelData = [...registeredModelResponse.registered_models, ...onBoardedProjectModel.registered_models];
            } else if (registeredModelResponse?.registered_models) {
                mergeModelData = registeredModelResponse.registered_models;
            } else if (onBoardedProjectModel?.registered_models) {
                mergeModelData = onBoardedProjectModel.registered_models;
            } else {
                mergeModelData = [];
            }

            if (
                registeredModelResponse?.Response === 'No projects found for this user' &&
                onBoardedProjectModel?.Response === 'No projects found for this user'
            ) {
                setErrorMessage(
                    <>
                        To access DLOps projects you must first establish access to the AI observability platform. For onboarding guidance, please reach out to
                        our DLOps dedicated team at{' '}
                        <a href="mailto:dl-pepeedlops@pepsico.com" style={{ color: '#1378D4' }}>
                            dl-pepeedlops@pepsico.com
                        </a>
                        .
                    </>
                );
                setLoading(false);
                setTechnicalError(true);
                return;
            }
            if (mergeModelData.length > 0) {
                localStorage.setItem('project_Details', JSON.stringify(mergeModelData));
                setLoading(false);
                const uniqueProjectNames = new Map();

                mergeModelData.forEach((model) => {
                    if (Array.isArray(model.tags)) {
                        const projectNameTag = model?.tags.find((tag) => tag.key === 'project_name');

                        if (projectNameTag && projectNameTag.value) {
                            const projectName = projectNameTag.value;
                            const { description } = model;

                            if (!uniqueProjectNames.has(projectName)) {
                                uniqueProjectNames.set(projectName, description);
                            }
                        }
                    }

                    // if (Array.isArray(model.latest_versions)) {
                    //     model.latest_versions.forEach((version) => {
                    //         if (Array.isArray(version.tags)) {
                    //             const projectNameTag = version.tags.find((tag) => tag.key === 'project_name');

                    //             if (projectNameTag && projectNameTag.value) {
                    //                 const projectName = projectNameTag.value;
                    //                 const { description } = version;

                    //                 if (!uniqueProjectNames.has(projectName)) {
                    //                     uniqueProjectNames.set(projectName, description);
                    //                 }
                    //             }
                    //         }
                    //     });
                    // }
                });

                const projectNames = Array.from(uniqueProjectNames);
                setUniqueProjectDetails(projectNames);
            } else {
                setLoading(false);
                setErrorMessage(
                    <>
                        An error occurred while fetching projects. If you need assistance, please reach out to our team at{' '}
                        <a href="mailto:dl-pepeedlops@pepsico.com" style={{ color: '#1378D4' }}>
                            dl-pepeedlops@pepsico.com
                        </a>
                        .
                    </>
                );
                setTechnicalError(false);
            }
        } catch (err) {
            setLoading(false);
            setTechnicalError(false);
            setErrorMessage(
                <>
                    An error occurred while fetching projects. If you need assistance, please contact our support team at{' '}
                    <a href="mailto:dl-pepeedlops@pepsico.com" style={{ color: '#1378D4' }}>
                        dl-pepeedlops@pepsico.com
                    </a>
                    .
                </>
            );
        }
    };

    useEffect(() => {
        getProjectList(localStorageToken);
    }, [localStorageToken]);

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                <Typography variant="body2" sx={ProgressIcon.circularProgressColor} style={{ marginLeft: '10px' }}>
                    Loading
                </Typography>
            </Box>
        );
    }
    const handleCreateProject = () => {
        localStorage.removeItem('createDefaulttags');
        localStorage.removeItem('newlyAddedtags');
        localStorage.removeItem('create_project');
        localStorage.removeItem('modelFormData');
        localStorage.setItem('all_project_list', JSON.stringify(uniqueProjectDetails));
        history.push('/dlops-model-onboarding');
    };
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>DLOPS Project Dashboard</title>
            </Helmet>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        <Item>
                            <div
                                style={{
                                    marginLeft: '28px',
                                    marginTop: '8px',
                                    fontSize: '14px',
                                }}
                            >
                                <span>DLOPS</span>
                                {/* <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleNavigation()}>
                                    /Overview
                                </span> */}
                                <span style={{ fontWeight: 'bold', color: '#1378D4' }}>/Projects</span>
                            </div>
                        </Item>
                    </Typography>
                </Grid>
            </Grid>
            {errorMessage ? (
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '70px',
                    }}
                >
                    <Box
                        style={{
                            width: '584px',
                            height: '218px',
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            border: '2px solid #D7E5F8',
                            borderRadius: '16px',
                            opacity: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '20px',
                            zIndex: 1000,
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '20px',
                            }}
                        >
                            <img src={RoboNoProjects} alt="roboDlops" style={{ marginRight: '40px', marginBottom: '17px' }} />
                            <Box>
                                <Box
                                    style={{
                                        textAlign: 'left',
                                        color: '#000000',
                                        font: 'normal normal bold 19px/24px PP Object Sans',
                                        paddingBottom: '10px',
                                    }}
                                >
                                    {isTechnicalError ? 'AI observability access required' : 'Technical Issue'}
                                </Box>

                                <Typography
                                    variant="body1"
                                    style={{
                                        font: 'normal normal normal 14px/21px PP Object Sans',
                                        width: '317px',
                                        height: '103px',
                                        textAlign: 'left',
                                        letterSpacing: '-0.07px',
                                    }}
                                >
                                    {errorMessage}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <div style={{ marginLeft: '34px', marginTop: '50px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} className="row">
                    <Card
                        sx={{
                            maxWidth: 275,
                            borderRadius: '12px',
                            margin: '10px',
                            width: '300px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F0F8FF',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            position: 'relative',
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                padding: '16px',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '60px',
                                    height: '60px',
                                    backgroundColor: '#1378D4',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: '16px',
                                }}
                            >
                                <AddCircleOutline style={{ color: 'white', fontSize: '30px' }} onClick={handleCreateProject} />
                            </Box>

                            <Typography
                                variant="h6"
                                sx={{
                                    color: '#1378D4',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    letterSpacing: '1px',
                                }}
                            >
                                Create New Project
                            </Typography>
                        </CardContent>
                    </Card>
                    {uniqueProjectDetails &&
                        uniqueProjectDetails.length > 0 &&
                        uniqueProjectDetails.map((item, index) => (
                            <Card sx={{ maxWidth: 275, borderRadius: '12px', margin: '10px', width: '300px' }} key={index}>
                                <CardContent>
                                    <Tooltip title={item[0]}>
                                        <Typography
                                            sx={{
                                                fontSize: 20,
                                                fontWeight: 500,
                                                color: '#000000',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            <img src={stickyNotes} alt="Sticky Notes" style={{ marginRight: '16px' }} />
                                            {item[0]}
                                        </Typography>
                                    </Tooltip>
                                    <hr />
                                    {/* <Typography sx={{ fontSize: 13, color: '#1378D4' }} component="div">
                                        Use Case
                                    </Typography> */}
                                    <div>
                                        <Typography
                                            sx={{
                                                fontSize: 13,
                                                color: '#000000',
                                                marginTop: '10px',
                                                minHeight: '68px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                overflow: 'visible',
                                                lineHeight: '24px',
                                                marginBottom: '20px',
                                                WebkitLineClamp: '3',
                                            }}
                                            variant="body2"
                                        >
                                            {item[1]}
                                        </Typography>
                                    </div>
                                </CardContent>
                                <CardActions sx={{ float: 'right' }}>
                                    <Button
                                        data-testid="ViewModels"
                                        style={{
                                            background: '#309BF8 0% 0% no-repeat padding-box',
                                            borderRadius: '6px',
                                            color: '#ffffff',
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            marginBottom: '15px',
                                            textTransform: 'none',
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                        }}
                                        onClick={() => goToRegistry(item[0])}
                                    >
                                        Models
                                    </Button>
                                </CardActions>
                            </Card>
                        ))}
                </div>
            )}
        </ErrorBoundary>
    );
};

export default ProjectDashboard;
