import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Grid, FormControl, Select, MenuItem, InputLabel, IconButton, FormHelperText } from '@mui/material';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorFallback from 'components/error-fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { getLoggedUserGPID } from 'utils';
// import { LoggedUserEmailId } from '../utils/index';
import { Item } from '../style';

const CreateNewModel = () => {
    const [modelName, setModelName] = useState('');
    const [modelDescription, setModelDescription] = useState('');
    const [modelVersion, setModelVersion] = useState('');
    const [allListOfProjects, setAllListOfProjects] = useState('');
    const [modelType, setModelType] = useState('');
    const [modelNameError, setModelNameError] = useState('');
    const [modelVersionError, setModelVersionError] = useState('');
    const [modelTypeError, setModelTypeError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [metricsError, setMetricsError] = useState([]);
    const [newMetricsError, setNewMetricsError] = useState([]);
    const history = useHistory();
    const [newlyAddedEmails, setNewlyAddedEmails] = useState([]);
    const [inputEmails, setInputEmail] = useState('');
    const [metrics, setMetrics] = useState([{ key: 'accuracy', value: ['', ''] }]);
    const [newMetric, setNewMetric] = useState([]);

    const handleAddEmail = () => {
        if (inputEmails) {
            const totalLength = newlyAddedEmails.reduce((acc, email) => acc + email.length, 0) + inputEmails.length;

            // Check if the total length exceeds 200 characters
            if (totalLength > 200) {
                setEmailError('Total length of emails cannot exceed 200 characters.');
                return;
            }
            // Check if email already exists in the list
            if (newlyAddedEmails.includes(inputEmails)) {
                setEmailError('This email is already added.');
            }
            // Check for valid email format and that it ends with @pepsico.com
            else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmails) && inputEmails.endsWith('@pepsico.com')) {
                setNewlyAddedEmails([...newlyAddedEmails, inputEmails]);
                setInputEmail('');
                setEmailError('');
            }
            // Check for valid email but with wrong domain
            else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmails)) {
                setEmailError('Email must be a @pepsico.com address.');
            } else {
                setEmailError('Please enter a valid email address.');
            }
        } else {
            setEmailError('Email address cannot be empty.');
        }
    };
    const handleDeleteEmail = (emailToDelete) => {
        setNewlyAddedEmails(newlyAddedEmails.filter((email) => email !== emailToDelete));
    };
    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('modelFormData'));
        if (storedData) {
            setModelName(storedData.model_name || '');
            setModelDescription(storedData.model_desc || '');
            setModelVersion(storedData.model_version || '');
            setModelType(storedData.tags.find((tag) => tag.mkey === 'model_type')?.mvalue || '');
            setNewlyAddedEmails(storedData.tags.find((tag) => tag.mkey === 'distribution_list')?.mvalue.split(',') || []);
            // Retrieve and set existing metrics
            const metricsData = storedData.tags.find((tag) => tag.mkey === 'model_metrics');
            if (metricsData) {
                const parsedMetrics = metricsData.mvalue
                    .replace(/[['\]]/g, '') // Remove brackets and single quotes
                    .split(',')
                    .map((metric) => metric.trim());
                const accuracyMetrics = parsedMetrics.filter((metric) => metric.trim() === 'accuracy');
                // Set existing metrics
                const retrievedMetrics = accuracyMetrics.map((metric) => {
                    const metricTag = storedData.tags.find((t) => t.mkey === metric);
                    return {
                        key: metric,
                        value: metricTag ? JSON.parse(metricTag.mvalue) : ['', ''],
                    };
                });
                setMetrics(retrievedMetrics);
                // Filter and set newly added metrics
                const newMetricsData = storedData.tags.filter((tag) => tag.mkey !== 'model_metrics' && parsedMetrics.includes(tag.mkey));
                const notAccuracyMetrics = newMetricsData.filter((metric) => metric.mkey !== 'accuracy');
                const newMetrics = notAccuracyMetrics.map((tag) => {
                    try {
                        return {
                            key: tag.mkey,
                            value: JSON.parse(tag.mvalue),
                        };
                    } catch (error) {
                        // Handle or return default values if parsing fails
                        return {
                            key: tag.mkey,
                            value: [], // or some default value
                        };
                    }
                });
                setNewMetric(newMetrics);
            }
        }
        const storedProjects = JSON.parse(localStorage.getItem('all_project_list')) || [];
        setAllListOfProjects(storedProjects);
    }, []);
    const validateEmail = (email) => {
        const emailPattern = /\S+@\S+\.\S+/;
        return emailPattern.test(email);
    };
    const validateForm = () => {
        let isValid = true;
        // Model Name validation
        if (!modelName) {
            setModelNameError('Model Name is required');
            isValid = false;
        } else {
            const projectExists = allListOfProjects.some((project) => project[0].toLowerCase() === modelName.toLowerCase());
            if (projectExists) {
                setModelNameError('Model Name already exists. Please choose a different name.');
                isValid = false;
            } else {
                setModelNameError('');
            }
        }
        // Model Version validation
        const versionPattern = /^\d+(\.\d+)?$/; // Allows only numbers and decimals
        if (!modelVersion) {
            setModelVersionError('Model Version is required');
            isValid = false;
        } else if (!versionPattern.test(modelVersion)) {
            setModelVersionError('Please enter a valid number (e.g., 1 or 1.0)');
            isValid = false;
        } else {
            setModelVersionError('');
        }
        // Model Type validation
        if (!modelType) {
            setModelTypeError('Model Type is required');
            isValid = false;
        } else {
            setModelTypeError('');
        }
        // Model email Account
        if (!validateEmail(newlyAddedEmails)) {
            setEmailError('Please enter a valid email address');
            isValid = false;
        } else {
            setEmailError('');
        }
        metrics.forEach((metric, index) => {
            const fieldErrors = {};

            // Validation for 'key'
            if (!metric.key) {
                fieldErrors.key = 'Metric Key is required.';
                isValid = false;
            }

            // Validation for value1 (Accuracy)
            const value1 = parseFloat(metric.value[0]);
            if (Number.isNaN(value1) || value1 < 0.1 || value1 > 1.0) {
                fieldErrors.value1 = 'Metric value must be between 0.1 and 1.0.';
                isValid = false;
            }

            // Validation for value2 (Threshold)
            const value2 = parseFloat(metric.value[1]);
            if (Number.isNaN(value2) || value2 < 0.1 || value2 > 1.0) {
                fieldErrors.value2 = 'Metric threshold value must be between 0.1 and 1.0.';
                isValid = false;
            }

            const updatedErrors = [...metricsError];
            updatedErrors[index] = {
                ...updatedErrors[index],
                ...fieldErrors,
            };

            setMetricsError(updatedErrors);
        });
        // Validate newly added metrics
        newMetric.forEach((metric, index) => {
            const fieldErrors = {};

            // Validation for 'key'
            if (!metric.key) {
                fieldErrors.key = 'Metric Key is required.';
                isValid = false;
            }

            // Validation for value1 (Accuracy)
            const value1 = parseFloat(metric.value[0]);
            if (Number.isNaN(value1) || value1 < 0.1 || value1 > 1.0) {
                fieldErrors.value1 = 'Metric value must be between 0.1 and 1.0.';
                isValid = false;
            }

            // Validation for value2 (Threshold)
            const value2 = parseFloat(metric.value[1]);
            if (Number.isNaN(value2) || value2 < 0.1 || value2 > 1.0) {
                fieldErrors.value2 = 'Metric threshold value must be between 0.1 and 1.0.';
                isValid = false;
            }

            const updatedErrors = [...newMetricsError];
            updatedErrors[index] = {
                ...updatedErrors[index],
                ...fieldErrors,
            };

            setNewMetricsError(updatedErrors);
        });

        return isValid;
    };
    const prepareTagsData = () => {
        const allMetrics = [...metrics, ...newMetric];
        const metricsData = allMetrics.reduce((acc, metric) => {
            if (metric.value[0] || metric.value[1]) {
                acc.push({
                    mkey: metric.key,
                    mvalue: `[${metric.value.join(', ')}]`,
                });
            }
            return acc;
        }, []);
        // Create model_metrics entry
        const modelMetricsEntry = {
            mkey: 'model_metrics',
            mvalue: `['${metricsData.map((m) => m.mkey).join("', '")}']`,
        };
        // Create model_type entry
        const modelTypeEntry = {
            mkey: 'model_type',
            mvalue: modelType.toLowerCase(),
        };
        // Create distribution_list entry
        const distributionListEntry = {
            mkey: 'distribution_list',
            mvalue: newlyAddedEmails.join(','),
        };
        // Combine all into tags array
        return [modelMetricsEntry, ...metricsData, modelTypeEntry, distributionListEntry];
    };
    const handleSaveProject = () => {
        if (!validateForm()) {
            return;
        }
        const tags = prepareTagsData();
        // Store model details
        const storeModelDetails = {
            model_name: modelName,
            model_version: modelVersion,
            model_desc: modelDescription,
            model_createdby: getLoggedUserGPID(),
            // model_createdby_user_details: {
            //     name: getLoggedUserFullName(),
            //     mail_address: LoggedUserEmailId(),
            // },
            model_current_stage: 'Production',
            tags,
        };
        localStorage.setItem('modelFormData', JSON.stringify(storeModelDetails));
        setModelName('');
        setModelDescription('');
        history.push('/dlops-new-modeltags');
    };
    const handleCancel = () => {
        history.push('/dlops-projects');
        localStorage.removeItem('modelFormData');
        setModelName('');
        setModelDescription('');
        setModelNameError('');
    };
    const handleBack = () => {
        history.push('/dlops-model-onboarding');
        setModelName('');
        setModelDescription('');
        setModelNameError('');
    };
    const handleMetricChange = (index, field, value) => {
        const updatedMetrics = [...metrics];
        const updatedErrors = [...metricsError];
        let error = '';

        if (field === 'value1') {
            const value1 = parseFloat(value);
            if (Number.isNaN(value1) || value1 < 0.1 || value1 > 1.0) {
                error = 'Metric value must be between 0.1 and 1.0';
            }
            updatedMetrics[index].value[0] = value;
            updatedErrors[index] = { ...updatedErrors[index], value1: error }; // Set error for value1
        }

        if (field === 'value2') {
            const value2 = parseFloat(value);
            if (Number.isNaN(value2) || value2 < 0.1 || value2 > 1.0) {
                error = 'Metric threshold value must be between 0.1 and 1.0';
            }
            updatedMetrics[index].value[1] = value;
            updatedErrors[index] = { ...updatedErrors[index], value2: error }; // Set error for value2
        }

        setMetrics(updatedMetrics);
        setMetricsError(updatedErrors); // Update the error state
    };

    const handleAddMetric = () => {
        setNewMetric([...newMetric, { key: '', value: ['', ''] }]);
        setNewMetricsError([...newMetricsError, { key: '', value1: '', value2: '' }]); // Initialize errors
    };
    const handleRemoveMetric = (index) => {
        const updatedNewMetric = newMetric.filter((_, i) => i !== index);
        setNewMetric(updatedNewMetric);
        const updatedErrors = newMetricsError.filter((_, i) => i !== index);
        setNewMetricsError(updatedErrors);
    };
    const handleNewMetricChange = (index, field, value) => {
        // Prepare updated metric
        const updatedNewMetrics = [...newMetric];
        const updatedErrors = [...newMetricsError];
        let error = '';
        if (field === 'value1' || field === 'value2') {
            const parsedValue = parseFloat(value);

            // Validate the parsed value
            if (Number.isNaN(parsedValue) || parsedValue < 0.1 || parsedValue > 1.0) {
                error = `Metric ${field === 'value1' ? 'value' : 'threshold'} must be between 0.1 and 1.0`;
            } else {
                error = '';
            }

            // Update the corresponding value in the 'value' array (index 0 for 'value1', index 1 for 'value2')
            const valueIndex = field === 'value1' ? 0 : 1;
            updatedNewMetrics[index].value[valueIndex] = value;

            // Set error for the specific field
            updatedErrors[index] = { ...updatedErrors[index], [field]: error };
        } else {
            // Handle changes for 'key' (optional key validation)
            const trimmedValue = value.trim();
            updatedNewMetrics[index].key = trimmedValue;

            // You can add validation for 'key', for example, checking if it's empty
            if (!trimmedValue) {
                updatedErrors[index] = { ...updatedErrors[index], key: 'Metric Key is required.' };
            } else {
                updatedErrors[index] = { ...updatedErrors[index], key: '' };
            }
        }

        // Update state with the new metrics and errors
        setNewMetric(updatedNewMetrics);
        setNewMetricsError(updatedErrors);
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {' '}
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        <Item>
                            <div
                                style={{
                                    marginLeft: '28px',
                                    marginTop: '8px',
                                    fontSize: '14px',
                                }}
                            >
                                <span>DLOPS</span>
                                <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleCancel()}>
                                    /Projects
                                </span>
                                <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleBack()}>
                                    /Create New Project
                                </span>
                                <span style={{ fontWeight: 'bold', color: '#1378D4' }}>/Create New Model</span>
                            </div>
                        </Item>
                    </Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    top: '164px',
                    left: '27px',
                    width: '1500px',
                    height: '70px',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: '4px',
                    opacity: 1,
                    marginTop: '20px',
                    padding: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: '100px',
                }}
            >
                <Typography sx={{ fontSize: '18px', fontWeight: 'medium', color: '#000000' }} aria-label="Create New Model">
                    Create New Model
                </Typography>
            </Box>
            <Box
                sx={{
                    top: '164px',
                    left: '27px',
                    width: '1500px',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: '4px',
                    opacity: 1,
                    marginTop: '20px',
                    padding: '16px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: '100px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <TextField
                        variant="filled"
                        fullWidth
                        value={modelName}
                        onChange={(e) => {
                            setModelName(e.target.value);
                            if (modelNameError) setModelNameError('');
                        }}
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>MODEL NAME</Typography>
                                <Typography sx={{ color: '#FF0000', marginLeft: '2px' }}>*</Typography>
                            </Box>
                        }
                        error={!!modelNameError}
                        helperText={modelNameError}
                        sx={{
                            marginBottom: '20px',
                            '& .MuiFilledInput-root': {
                                backgroundColor: '#F2F2F2',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#000000',
                                fontSize: '15px',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: '#888888',
                            },
                            width: '762px',
                            height: '55px',
                            fontSize: '16px',
                            fontWeight: 'medium',
                        }}
                    />
                    {/* {error && <Typography sx={{ color: '#FF0000', fontSize: '14px', marginBottom: '10px' }}>{error}</Typography>} */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginBottom: '20px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Typography sx={{ fontSize: '14px', fontWeight: 'medium', color: '#000000' }} aria-label="Model Description">
                                Model Description
                            </Typography>
                            <Typography
                                sx={{
                                    marginLeft: '10px',
                                    color: '#1378D4',
                                    fontSize: '13px',
                                    alignSelf: 'flex-start',
                                }}
                            >
                                Optional
                            </Typography>
                        </Box>
                        <TextField
                            variant="filled"
                            fullWidth
                            multiline
                            rows={4}
                            value={modelDescription}
                            onChange={(e) => {
                                setModelDescription(e.target.value);
                            }}
                            sx={{
                                marginTop: '8px',
                                '& .MuiFilledInput-root': {
                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#000000',
                                    fontSize: '15px',
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#888888',
                                },
                                width: '762px',
                                border: '1px solid #1378D4',
                                borderRadius: '4px',
                            }}
                        />
                    </Box>
                    <TextField
                        variant="filled"
                        fullWidth
                        value={modelVersion}
                        onChange={(e) => {
                            setModelVersion(e.target.value);
                            if (modelVersionError) {
                                setModelVersionError('');
                            }
                        }}
                        error={!!modelVersionError}
                        helperText={modelVersionError}
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>MODEL VERSION</Typography>
                                <Typography sx={{ color: '#FF0000', marginLeft: '2px' }}>*</Typography>
                            </Box>
                        }
                        sx={{
                            marginBottom: '20px',
                            '& .MuiFilledInput-root': {
                                backgroundColor: '#F2F2F2',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#000000',
                                fontSize: '15px',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: '#888888',
                            },
                            width: '450px',
                            height: '55px',
                            fontSize: '16px',
                            fontWeight: 'medium',
                        }}
                    />
                    <FormControl variant="filled" sx={{ width: '450px', marginBottom: '20px' }} error={!!modelTypeError}>
                        <InputLabel id="model-type-label">
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ color: '#000000' }}>MODEL TYPE</Typography>
                                <Typography sx={{ color: '#FF0000', marginLeft: '2px' }}>*</Typography>
                            </Box>
                        </InputLabel>
                        <Select
                            labelId="model-type-label"
                            value={modelType}
                            onChange={(e) => {
                                setModelType(e.target.value);
                                if (modelTypeError) setModelTypeError('');
                            }}
                            sx={{
                                '& .MuiFilledInput-root': {
                                    backgroundColor: '#F2F2F2',
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#000000',
                                    fontSize: '15px',
                                },
                            }}
                        >
                            <MenuItem value="Classification">Classification</MenuItem>
                            <MenuItem value="Detection">Detection</MenuItem>
                        </Select>
                        {modelTypeError && <FormHelperText>{modelTypeError}</FormHelperText>}
                    </FormControl>

                    <Box>
                        <Typography sx={{ fontWeight: 'medium', fontSize: '14px', color: '#000000', display: 'flex', alignItems: 'center' }}>
                            Distribution List
                            <Typography sx={{ color: '#FF0000', marginLeft: '2px' }}>*</Typography>
                        </Typography>

                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <TextField
                                    id="filled-email-input"
                                    label="ADD EMAIL ACCOUNT"
                                    type="email"
                                    autoComplete="current-email"
                                    variant="filled"
                                    value={inputEmails}
                                    onChange={(e) => {
                                        setInputEmail(e.target.value);
                                        if (emailError) {
                                            setEmailError('');
                                        }
                                    }}
                                    error={!!emailError}
                                    helperText={emailError}
                                    sx={{
                                        marginBottom: '20px',
                                        '& .MuiFilledInput-root': {
                                            backgroundColor: '#F2F2F2',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#000000',
                                            fontSize: '15px',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#888888',
                                        },
                                        width: '450px',
                                        height: '55px',
                                        fontSize: '16px',
                                        fontWeight: 'medium',
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '121px',
                                        height: '40px',
                                        backgroundColor: '#1378D4',
                                        marginTop: '-8px',
                                    }}
                                    onClick={handleAddEmail}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ marginBottom: '15px', marginTop: '0px' }}>
                        {newlyAddedEmails.map((email, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginBottom: '10px',
                                    background: newlyAddedEmails.includes(email) ? '#E0F7FA' : '#FFFFFF',
                                    borderRadius: '4px',
                                    width: '450px',
                                    height: '60px',
                                    border: '1px solid #D6D6D6',
                                    padding: '10px',
                                    paddingTop: '15px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '8px',
                                    }}
                                >
                                    <Typography
                                        title={email}
                                        arrow
                                        sx={{
                                            color: '#000000',
                                            fontSize: '14px',
                                            maxWidth: '300px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {email}
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            width: '94px',
                                            height: '36px',
                                            border: '1px solid #1378D4',
                                            color: '#1378D4',
                                            borderRadius: '4px',
                                        }}
                                        onClick={() => handleDeleteEmail(email)}
                                    >
                                        Remove
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                    </Box>

                    <Typography sx={{ fontWeight: 'medium', fontSize: '14px', color: '#000000', display: 'flex', alignItems: 'center' }}>
                        Model Metrics
                        <Typography sx={{ color: '#FF0000', marginLeft: '2px' }}>*</Typography>
                    </Typography>
                    <Box sx={{ color: '#000000', wordBreak: 'break-word', marginTop: '10px' }}>
                        {metrics.map((metric, index) => (
                            <Grid container spacing={2} alignItems="flex-start" key={index}>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Metric Key"
                                        variant="outlined"
                                        value={metric.key}
                                        disabled={metric.key === 'accuracy'}
                                        onChange={(e) => handleMetricChange(index, 'key', e.target.value)}
                                    />
                                    <Box sx={{ minHeight: '20px' }}>
                                        {metricsError[index]?.general && (
                                            <Typography color="error" sx={{ marginTop: '4px' }}>
                                                {metricsError[index]?.general}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        label="Metric value"
                                        variant="outlined"
                                        value={metric.value[0]}
                                        onChange={(e) => handleMetricChange(index, 'value1', e.target.value)}
                                    />
                                    <Box sx={{ minHeight: '20px' }}>
                                        {metricsError[index]?.value1 && (
                                            <Typography color="error" sx={{ marginTop: '4px' }}>
                                                {metricsError[index]?.value1}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        label="Metric threshold"
                                        variant="outlined"
                                        value={metric.value[1]}
                                        onChange={(e) => handleMetricChange(index, 'value2', e.target.value)}
                                    />
                                    <Box sx={{ minHeight: '20px' }}>
                                        {metricsError[index]?.value2 && (
                                            <Typography color="error" sx={{ marginTop: '4px' }}>
                                                {metricsError[index]?.value2}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                <Button
                                    variant="contained"
                                    sx={{ marginTop: '30px', marginLeft: '10px', backgroundColor: '#1378D4', color: '#ffffff' }}
                                    onClick={handleAddMetric}
                                >
                                    Add New Metric
                                </Button>
                            </Grid>
                        ))}

                        {newMetric.map((item, index) => (
                            <Grid container spacing={2} alignItems="flex-start" sx={{ marginTop: '2px' }} key={index}>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Metric Key"
                                        variant="outlined"
                                        value={item.key}
                                        onChange={(e) => handleNewMetricChange(index, 'key', e.target.value)}
                                    />
                                    <Box sx={{ minHeight: '20px' }}>
                                        {newMetricsError[index]?.key && (
                                            <Typography color="error" sx={{ marginTop: '4px' }}>
                                                {newMetricsError[index]?.key}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        label="Metric value"
                                        variant="outlined"
                                        value={item.value[0]}
                                        onChange={(e) => handleNewMetricChange(index, 'value1', e.target.value)}
                                    />
                                    <Box sx={{ minHeight: '20px' }}>
                                        {newMetricsError[index]?.value1 && (
                                            <Typography color="error" sx={{ marginTop: '4px' }}>
                                                {newMetricsError[index]?.value1}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        label="Metric threshold"
                                        variant="outlined"
                                        value={item.value[1]}
                                        onChange={(e) => handleNewMetricChange(index, 'value2', e.target.value)}
                                    />
                                    <Box sx={{ minHeight: '20px' }}>
                                        {newMetricsError[index]?.value2 && (
                                            <Typography color="error" sx={{ marginTop: '4px' }}>
                                                {newMetricsError[index]?.value2}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                <Grid item xs={2} sx={{ marginTop: '20px' }}>
                                    <IconButton onClick={() => handleRemoveMetric(index)} aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </Box>

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button variant="contained" sx={{ backgroundColor: '#1378D4', marginTop: '50px' }} onClick={handleBack}>
                            Back
                        </Button>
                        <Button variant="outlined" sx={{ marginTop: '50px' }} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" sx={{ backgroundColor: '#1378D4', marginTop: '50px', marginLeft: '500px' }} onClick={handleSaveProject}>
                            Add Tag
                        </Button>
                    </Box>
                </Box>
            </Box>
        </ErrorBoundary>
    );
};

export default CreateNewModel;
