import React from 'react';
import { Grid, Box, TableContainer, Table, TableBody, Typography, Divider } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Forms from 'pages/project-onboarding/components/Form';
import DeleteIcon from '@mui/icons-material/Delete';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm, isSonarQubeRepoAvailable } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import SonarQubeUser from 'pages/project-onboarding/components/Form/ServiceMembers/SonarQubeUser';
import { SonarQubeRepo } from 'pages/project-onboarding/components/Form/SonarQubeRepo';
import ServiceDialog from './ServiceDialog';

const SonarQubeConfiguration = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [deleteMember, setDeleteMember] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [, setRepoAvailable] = useRecoilState(isSonarQubeRepoAvailable);

    const sonarQubeCodeRepoApi = useMutation(() => axios.get(`${endPoints.day2Operation.ProjectOnboarding.Jwt.sonarQubeRepo}/${order?.projectName}`));

    const handleDeleteMember = () => {
        if (order?.sonarQubeUserDetails) {
            if (deleteMember?.gpid === order?.sonarQubeUserDetails?.gpid) {
                setOrder({
                    ...order,
                    sonarQubeUserList: null,
                    sonarQubeUserDetails: null,
                });
                setValidForm({ ...isValidForm, isDuplicateSonarQubeUser: false });
            }
        }
        setOrder({
            ...order,
            sonarQubeUserList: order?.sonarQubeUserList?.filter((item) => item.gpid !== deleteMember?.gpid),
        });
        setValidForm({ ...isValidForm, isSonarQubeMaxMemberLimit: false });
    };

    const isExistsInOptions = (sonarQubeCodeRepo) => order?.sonarQubeRepoList?.find((option) => sonarQubeCodeRepo === option.value);

    React.useEffect(() => {
        if (order?.sonarQubeRepoList && order?.sonarQubeRepoList?.length === 1 && !order?.sonarQubeCodeRepo) {
            setOrder({ ...order, sonarQubeCodeRepo: order?.sonarQubeRepoList?.[0].value });
        }
        if (order?.sonarQubeRepoList && order?.sonarQubeRepoList?.length && order?.sonarQubeCodeRepo) {
            const sonarQubeCodeRepo = isExistsInOptions(order?.sonarQubeCodeRepo);
            setOrder({ ...order, sonarQubeCodeRepo: sonarQubeCodeRepo?.value });
        }
    }, [order?.sonarQubeRepoList]);

    React.useEffect(() => {
        if (sonarQubeCodeRepoApi?.isSuccess) {
            if (Object.hasOwn(sonarQubeCodeRepoApi?.data, 'data'))
                setOrder({
                    ...order,
                    sonarQubeRepoList: sonarQubeCodeRepoApi?.data?.data?.repoNames?.map((app) => ({ label: app, value: app })),
                });
            else {
                setRepoAvailable(false);
            }
        }
    }, [sonarQubeCodeRepoApi?.isSuccess]);

    React.useEffect(() => {
        if (
            order?.projectName &&
            order?.sonarQubeRepoList?.length === 0 &&
            order?.projectDetails &&
            order?.projectDetails?.repos !== 0 &&
            order?.projectDetails?.repos !== null
        ) {
            //   resetOptions();
            sonarQubeCodeRepoApi.mutate();
        }
    }, [order?.projectName, order?.sonarQubeRepoList?.length, order?.projectDetails]);

    const handleNo = () => {
        setIsOpen(false);
    };
    const handleYes = () => {
        handleDeleteMember();
        setIsOpen(false);
    };

    const getServiceAccoutQuestion = (isADOProject) => {
        if ((isADOProject === 'yes' && order?.onboardServiceAccount) || (isADOProject === 'no' && order?.newProjectOnboardServiceAccount)) {
            return (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ ...ProjectOnboardingStyles?.formField, marginTop: order?.isADOProject === 'yes' ? '16px' : '0', marginBottom: '16px' }}
                >
                    <Forms.AvailableServiceAccountQuestion />
                </Grid>
            );
        }
        return null;
    };

    return (
        <Box data-testid="sonarqube-config">
            <Grid container spacing={4}>
                {order?.isADOProject === 'yes' && (
                    <Grid item xs={12} sm={12} sx={ProjectOnboardingStyles?.formField} mb={2}>
                        <Forms.OnboardQuestion />
                    </Grid>
                )}
            </Grid>{' '}
            <Grid container spacing={4} mt={1}>
                {order?.isADOProject === 'no' && (
                    <Grid item xs={12} sm={12} sx={ProjectOnboardingStyles?.formField} mb={2}>
                        <Forms.NewProjectOnboardQuestion />
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: order?.isADOProject === 'yes' ? '-32px' : '0' }}>
                {getServiceAccoutQuestion(order?.isADOProject, order?.onboardRepositories)}
                {(order?.onboardServiceAccount || order?.newProjectOnboardServiceAccount) && order?.isServiceAccountExists === 'yes' && (
                    <Grid item xs={12} sm={6} sx={ProjectOnboardingStyles?.formField} mt={0} pr={2}>
                        <Forms.ServiceAccount />
                    </Grid>
                )}
            </Grid>
            <Box display="flex" flexDirection="column" mt={2}>
                {order?.projectDetails && order?.projectDetails?.repos !== 0 && order?.projectDetails?.repos !== null && order?.onboardRepositories && (
                    <>
                        <Box display="flex">
                            <Typography fontWeight="500">Code Repository *</Typography>
                        </Box>
                        <SonarQubeRepo />
                    </>
                )}

                {order?.onboardMembers || (order?.isADOProject === 'no' && order?.newProjectOnboardMembers) ? (
                    <>
                        <Box display="flex" flexDirection="column" mt={0} gap={1}>
                            <Typography fontWeight="500" mr={1}>
                                Member Selection *
                            </Typography>
                            <Grid item xs={12} sm={6}>
                                <SonarQubeUser />
                            </Grid>
                            <Typography fontWeight="500" mr={1} mb={1}>
                                Member Access List *
                            </Typography>
                        </Box>
                        <Divider />
                        <Box display="flex">
                            {order?.sonarQubeUserList?.length > 0 ? (
                                <Box width="100%">
                                    <TableContainer>
                                        <Table size="small">
                                            <TableBody>
                                                {order?.sonarQubeUserList &&
                                                    order?.sonarQubeUserList?.length &&
                                                    order?.sonarQubeUserList?.map((item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell align="left" width="1">
                                                                <Typography>{item?.gpid || ''}</Typography>
                                                            </TableCell>
                                                            <TableCell align="left" width="1">
                                                                {item?.name || '-'}
                                                            </TableCell>
                                                            <TableCell align="left" width="1">
                                                                {item?.email === 'null' || !item?.email ? '-' : item?.email}
                                                            </TableCell>
                                                            <TableCell width="1">
                                                                <DeleteIcon
                                                                    sx={ProjectOnboardingStyles?.removeBtn}
                                                                    data-testid="delete-member"
                                                                    color="error"
                                                                    onClick={() => {
                                                                        setDeleteMember(item);
                                                                        setIsOpen(true);
                                                                    }}
                                                                    className="animate-opacity"
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            ) : (
                                <Box mt={2}>
                                    <Typography variant="body1">No Members Selected</Typography>
                                </Box>
                            )}
                        </Box>
                        <Box display="flex" alignItems="baseline" justifyContent="space-between" mt={2}>
                            {order?.sonarQubeUserList && order?.sonarQubeUserList?.length > 0 ? (
                                <Typography>
                                    <b>{order?.sonarQubeUserList?.length}</b> {order?.sonarQubeUserList?.length < 2 ? 'Member' : 'Members'} Selected{' '}
                                </Typography>
                            ) : (
                                <>&nbsp;</>
                            )}
                        </Box>
                    </>
                ) : null}
            </Box>
            <ServiceDialog isOpen={isOpen} member={deleteMember?.name} handleNo={handleNo} handleYes={handleYes} data-testid="handle-yes" />
        </Box>
    );
};

export default React.memo(SonarQubeConfiguration);
