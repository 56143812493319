export const windowsCatalogDefinitionId = ['AZWINVM', 'AZWINVM2016', 'AZWINVM2019', 'AZWINVM2022'];
export const linuxCatalogDefinitionId = ['AZRHELVM', 'AZRHEL8LVM', 'AZRHEL7LVM', 'AZRHEL8VM', 'AZRHEL7VM', 'AZOEL7VM', 'AZSLES15VM', 'AZSLES12VM', 'AZOEL8VM'];
export const linuxRHEL8CatalogDefinitionId = ['AZRHEL8LVM', 'AZRHEL8VM'];
export const linuxSLESCatalogDefinitionId = ['AZSLES15VM', 'AZSLES12VM'];
export const linuxOELCatalogDefinitionId = ['AZOEL7VM', 'AZOEL8VM'];
export const kafkaCatalogDefinitionId = ['AZKAFKA', 'CFKAFKA'];
export const AlbCatalogDefinitionId = ['AZLOADBAL'];
export const keyvaultCatalogDefinitionId = ['AZKEYVAULT'];
export const nsgCatalogDefinitionId = ['NSGAS'];
export const eventhubDefinitionId = ['AZEVENTHUB'];
export const pECatalogDefinitionId = ['AZUREPE'];
export const oracleIaaSDefinitionId = ['AZRHELORA19C', 'AZOELORA19C'];
export const oelOracleCatalogDefinitionId = ['AZOELORA19C'];
export const sqlIaaSCatalogDefinitionId = ['AZWINSQLIAAS2019', 'AZWINSQLIAAS'];
export const storageCatalogDefinitionId = ['AZSTORAGE'];
export const eipStackCatalogDefinitionId = ['CMPAPPMICROSERVICE'];
export const sqlPaaSCatalogDefinitionId = ['AZSQLPAAS'];
export const aksCatalogDefinitionId = ['AZAKSNS'];
export const adfCatalogDefinitionId = ['AZDATAFACTORY'];
export const couchbaseDbDefinitionId = ['COUCHBASEDB'];
export const RICatalogDefinitionId = ['RI001', 'RI002', 'RI003', 'RI003O', 'RI003A', 'RI004', 'RI004A', 'RI005', 'RI001A'];
export const Day2CatalogDefinitionId = ['DAY2ADDMEMBERS'];
export const ServiceAccountDefinitionId = ['DAY2SERVICEACCOUNT'];
export const SecurityToolCatalogDefinitionId = ['DAY2SECURITYTOOLONBOARD'];
export const OnBoardDevelopersCatalogDefinitionId = ['DAY2DEVELOPERONBOARD'];
export const SsoOktaCatalogDefinitionId = ['DAY2ADDSSOOKTA'];
export const ApplicationGateWayURLCatalogDefinitionID = ['AZAGWURLONBOARD'];
export const MdtCatalogDefinitionId = ['DAY2MDTONBOARD'];
export const ProjectOnboardCatalogDefinitionId = ['DAY2ADOONBOARD'];
export const VirtualServiceCatalogDefinitionId = ['AZAKSVIRTUALSERVICE'];
export const SlesPgtCatalogDefinitionId = ['AZPGTSAPSLES15VM'];
