import Axios from 'axios';
import toast from 'react-hot-toast';
// eslint-disable-next-line import/no-cycle
import { getAccessToken, getLoggedUserClaims, getLoggedUserFullName, getEnv } from 'utils';
import { endPoints } from './endpoints';
import { isBlob, customToast, isOktaToken } from './utils';

let config = {
    url: '',
    baseURL: '',
    headers: {
        Authorization: '',
    },
};

const axios = Axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/json',
    },
    ...(process.env.REACT_APP_ENABLE_TIMEOUT === 'true' && { timeout: `${process.env.REACT_APP_API_TIMEOUT}` }),
});

const handleLogOut = async () => {
    const newUser = localStorage.getItem('newUser');
    localStorage.clear();
    window.location.replace(getEnv('REACT_APP_OKTA_LOGOUT_URL'));
    localStorage.setItem('newUser', newUser);
};

const HandleUnAuthorized = async () => {
    if (!config.url?.includes('auth')) {
        const data = await axios.get(endPoints.user.auth);
        if (data && data?.isSuccess) {
            localStorage.setItem('accessToken', data.data.accessToken);
            localStorage.setItem('pages', JSON.stringify(data.data.authorizedTo));
            window.location.reload();
        } else {
            toast.dismiss();
            customToast(['Unable to retrieve your session']);
            // toast.error('Unable to retrieve your session');
            await handleLogOut();
        }
    } else {
        toast.dismiss();
        customToast(['Unable to retrieve your session']);
        // toast.error('Unable to retrieve your session');
        await handleLogOut();
    }
};

axios.interceptors.request.use((axiosConfig) => {
    config = axiosConfig;
    if (config.url?.includes('.json')) {
        config.baseURL = '/static/api';
    }
    if (
        [
            // endPoints.applicationGateWayURL.submitApi,
            // endPoints.applicationGateWayURL.resubmit,
            endPoints?.riServices?.RI001?.Jwt?.submitOrder,
            endPoints?.riServices?.RI001?.Jwt?.reSubmit,
            endPoints?.riServices?.RI001A?.Jwt?.submitOrder,
            endPoints?.riServices?.RI001A?.Jwt?.reSubmit,
        ]?.includes(config.url)
    ) {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    // if (config.url?.includes('/cmp/')) {
    //     config.baseURL = 'https://localhost:8443';
    // }
    const accessToken = localStorage.getItem('accessToken');
    const oktaToken = getAccessToken();

    const token = config.url?.includes('auth') ? oktaToken : accessToken;

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        const userClaims = getLoggedUserClaims();
        if (userClaims) {
            const claims = userClaims?.accessToken?.claims;
            config.headers['X-User-Info'] = isOktaToken(config.url)
                ? oktaToken
                : btoa(unescape(encodeURIComponent(`${claims.gpid}:${getLoggedUserFullName()}:${claims.email}`)));
        }
    }
    if (isBlob(config.url)) {
        config.responseType = 'blob';
    }
    return config;
});

// response interceptor
axios.interceptors.response.use(
    (response) => {
        const { data } = response;
        if (response.status === 200) {
            return data;
        }
        if (response.status === 401) {
            HandleUnAuthorized();
        }
        return Promise.reject(new Error(response.statusText || 'Error'));
    },
    (error) => {
        if (error.response && error.response.status) {
            const currentRequestUrl = error?.response?.config?.url?.split('?')[0];
            const excludeADGroupValidationUrl = [
                endPoints.riServices.RI001.adGroupValidation,
                endPoints.riServices.RI001.adGroupValidation,
                endPoints.ssp.oracle.expDBvalidation,
            ];
            const excludeUrl = [endPoints.ssp.aks.validateADGroup, endPoints.ssp.aks.validate, endPoints?.ssp?.privateEndpointV2?.validate];
            const excludedUrlSubstrings = [endPoints.ssp.kafka.validateNewLdapGrp, endPoints.ssp.kafka.validateBgLdapGrp];
            const isUrlExcludedSubstrings = excludedUrlSubstrings.some((substring) => currentRequestUrl.includes(substring));
            if (error?.response?.status === 400 && (excludeUrl.includes(currentRequestUrl) || isUrlExcludedSubstrings)) return error?.response?.data;
            if (error?.response?.status === 409 && excludeUrl.includes(currentRequestUrl)) return error?.response?.data;
            switch (error.response.status) {
                case 401:
                    HandleUnAuthorized();
                    break;
                case 403:
                    customToast(error.response.data?.messages || [`403 Forbidden:- ${error?.response?.config?.url}`]);
                    // toast.error(error.response.data?.messages?.shift() || '@api Error: 403 Forbidden');
                    break;
                case 404:
                    customToast(error.response.data?.messages || [`404 URL Not Found:- ${error?.response?.config?.url}`]);
                    // toast.error(error.response.data?.messages?.shift() || '@api Error: URL Not Found');
                    break;
                case 406:
                    customToast(error.response.data?.messages || [`406 Not Acceptable:- ${error?.response?.config?.url}`]);
                    // toast.error(error.response.data?.messages?.shift() || '@api Error');
                    break;
                case 500:
                    customToast(error.response.data?.messages || [`406 Not Acceptable:- ${error?.response?.config?.url}`]);
                    // toast.error(error.response.data?.messages?.shift() || '@api Error');
                    break;
                default:
                    if (!excludeADGroupValidationUrl?.includes(currentRequestUrl)) {
                        customToast(error.response.data?.messages || [`${error.response.status} Error:- ${error?.response?.config?.url}`]);
                    }
                    // toast.error(error.response.data?.messages[0] || '@api Error');
                    return Promise.reject(error.response.data?.messages);
            }
        } else if (error?.code === 'ECONNABORTED') {
            customToast([`${error?.message} at ${error?.config?.url}` || '@api Timeout exception']);
            // toast.error(`${error?.message} at ${error?.config?.url}` || '@api Timeout exception');
        } else {
            // eslint-disable-next-line
            console.log('Error', error?.message);
            customToast([error?.message || '@server Error']);
            // toast.error(error?.message || '@server Error');
        }
        return Promise.reject(error);
    }
);

export default axios;
export * from './endpoints';
