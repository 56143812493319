import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { getLoggedUserGPID } from 'utils';
// import { LoggedUserEmailId } from '../utils/index';

const CreateNewProject = () => {
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [allListOfProjects, setAllListOfProjects] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();

    useEffect(() => {
        const storedAllProjectsList = JSON.parse(localStorage.getItem('all_project_list')) || [];
        const storedProject = JSON.parse(localStorage.getItem('create_project')) || [];
        setProjectName(storedProject.project_name);
        setProjectDescription(storedProject.project_desc);
        setAllListOfProjects(storedAllProjectsList);
    }, []);

    const handleSaveProject = () => {
        setError('');
        if (!projectName) {
            setError('Project Name is required');
            return;
        }
        let projectExists = false;
        allListOfProjects.forEach((project) => {
            if (project[0].toLowerCase() === projectName.toLowerCase()) {
                projectExists = true;
            }
        });
        if (projectExists) {
            setError('Project Name already exists. Please choose a different name.');
            return;
        }
        // Create new project object
        const newProject = {
            project_name: projectName,
            new_project: true,
            project_desc: projectDescription,
            project_createdby: getLoggedUserGPID(),
            // project_createdby_user_details: {
            //     name: getLoggedUserFullName(),
            //     email: LoggedUserEmailId(),
            // },
        };
        localStorage.setItem('create_project', JSON.stringify(newProject));
        setError('');
        history.push('/dlops-new-modelCreation');
    };

    const handleCancel = () => {
        localStorage.removeItem('create_project');
        history.push('/dlops-projects');
        setProjectName('');
        setProjectDescription('');
        setError('');
    };

    return (
        <Box
            sx={{
                top: '164px',
                left: '27px',
                width: '1500px',
                height: '400px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: '4px',
                opacity: 1,
                marginTop: '20px',
                padding: '16px',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginLeft: '100px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
            >
                <TextField
                    variant="filled"
                    value={projectName}
                    onChange={(e) => {
                        setProjectName(e.target.value);
                        if (error) setError('');
                    }}
                    label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>PROJECT NAME</Typography>
                            <Typography sx={{ color: '#FF0000', marginLeft: '2px' }}>*</Typography>
                        </Box>
                    }
                    sx={{
                        marginBottom: '20px',
                        '& .MuiFilledInput-root': {
                            backgroundColor: '#F2F2F2',
                        },
                        '& .MuiInputLabel-root': {
                            color: '#000000',
                            fontSize: '15px',
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                            color: '#888888',
                        },
                        width: '762px',
                        height: '55px',
                        fontSize: '16px',
                        fontWeight: 'medium',
                    }}
                />

                {error && <Typography sx={{ color: '#FF0000', fontSize: '14px', marginBottom: '10px' }}>{error}</Typography>}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginBottom: '20px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Typography sx={{ fontSize: '14px', fontWeight: 'medium', color: '#000000' }}>Project Description</Typography>
                        <Typography
                            sx={{
                                marginLeft: '10px',
                                color: '#1378D4',
                                fontSize: '13px',
                                alignSelf: 'flex-start',
                            }}
                        >
                            Optional
                        </Typography>
                    </Box>
                    <TextField
                        variant="filled"
                        fullWidth
                        multiline
                        rows={4}
                        value={projectDescription}
                        onChange={(e) => {
                            setProjectDescription(e.target.value);
                            if (error) setError('');
                        }}
                        sx={{
                            marginTop: '8px',
                            '& .MuiFilledInput-root': {
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#000000',
                                fontSize: '12px',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: '#888888',
                            },
                            width: '762px',
                            border: '1px solid #1378D4',
                            borderRadius: '4px',
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Button variant="contained" sx={{ backgroundColor: '#1378D4', marginTop: '50px' }} onClick={handleSaveProject}>
                        Next
                    </Button>
                    <Button variant="outlined" sx={{ marginTop: '50px' }} onClick={handleCancel}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default CreateNewProject;
